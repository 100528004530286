/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DocumentType = {
    BillOfLading: 'bill_of_lading',
    CarrierScannedBillOfLading: 'carrier_scanned_bill_of_lading',
    ProofOfDelivery: 'proof_of_delivery',
    CarrierWeightReport: 'carrier_weight_report',
    CommercialInvoice: 'commercial_invoice',
    PackingList: 'packing_list',
    CargoClaimsForm: 'cargo_claims_form',
    CertificateOfOrigin: 'certificate_of_origin',
    DangerousGoodsForm: 'dangerous_goods_form',
    PurchaseOrder: 'purchase_order',
    InsuranceCertificate: 'insurance_certificate',
    ExportLicense: 'export_license',
    CustomsDocument: 'customs_document',
    PickupPackage: 'pickup_package',
    Image: 'image',
    Unknown: 'unknown',
    ClaimsOriginalInvoice: 'claims_original_invoice',
    ClaimsPhoto: 'claims_photo',
    ClaimsOtherDocument: 'claims_other_document',
    ShipperPhoto: 'shipper_photo',
    InterlineBillOfLading: 'interline_bill_of_lading',
    EmailAttachment: 'email_attachment',
    CustomerEmailAttachment: 'customer_email_attachment',
    CarrierEmailAttachment: 'carrier_email_attachment',
    SentEmailAttachment: 'sent_email_attachment'
} as const;
export type DocumentType = typeof DocumentType[keyof typeof DocumentType];


export function instanceOfDocumentType(value: any): boolean {
    return Object.values(DocumentType).includes(value);
}

export function DocumentTypeFromJSON(json: any): DocumentType {
    return DocumentTypeFromJSONTyped(json, false);
}

export function DocumentTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentType {
    return json as DocumentType;
}

export function DocumentTypeToJSON(value?: DocumentType | null): any {
    return value as any;
}

