import Colors from 'Cargo/Colors';
import Icon from 'Cargo/Icons/Icon';
import Box from 'Cargo/Layout/Box';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';

interface ViewShipmentSectionProps {
    children: ReactNode;
    title: ReactNode;
    subtitle?: ReactNode;
    id: string;
}

const SectionTitle = styled.div`
    font-weight: var(--nhu-font-weight-bold);
    font-size: 30px;
    color: var(--freightsimple-color-normal-text);
`;

const SectionSubtitle = styled.div`
    font-weight: var(--nhu-font-weight-light);
    font-size: 18px;
    color: var(--freightsimple-color-light-text);
    text-align: center;

    & strong {
        color: var(--freightsimple-color-normal-text);
    }
`;

function ViewShipmentWarningSection(props: ViewShipmentSectionProps) {
    return (
        <div
            id={props.id}
            style={{
                width: '100%',
            }}
        >
            <Box
                style={{
                    width: '100%',
                    padding: '24px 40px 40px 40px',
                    marginBottom: '48px',
                }}
            >
                <Stack align="center" width="100%">
                    <Icon
                        name="globe-americas"
                        color={Colors.Blue}
                        size={80}
                        solid
                        style={{ marginBottom: '16px', marginTop: '16px' }}
                    />
                    <SectionTitle>{props.title}</SectionTitle>
                    {props.subtitle && (
                        <SectionSubtitle>{props.subtitle}</SectionSubtitle>
                    )}
                    <Spacer height={32} />
                    {props.children}
                </Stack>
            </Box>
        </div>
    );
}
export default ViewShipmentWarningSection;
