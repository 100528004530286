import Colors from 'Cargo/Colors';
import LinkButton from 'Cargo/Controls/LinkButton';
import CarrierLogo from 'Cargo/Icons/CarrierLogo';
import Icon from 'Cargo/Icons/Icon';
import Box, { BoxStyle } from 'Cargo/Layout/Box';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Stack from 'Cargo/Layout/Stack';
import { Legalese } from 'Cargo/Text/Text';
import { AddLineItemType } from 'Features/LineItems/Types/lineItemTypes';
import { AddLocationLocationType } from 'Features/Locations/Types/locationTypes';
import {
    HandlingUnitType,
    Hours,
    LineItem,
    Location,
    Quote,
    ShipmentState,
} from 'generated-openapi-client';
import { colorForShipmentState } from 'Helpers/colorForShipmentState';
import { describeDateRelative } from 'Helpers/describeDateRelative';
import { describeDateRelativeWithTime } from 'Helpers/describeDateRelativeWithTime';
import { describeHours } from 'Helpers/describeHours';
import { formatPostalCode } from 'Helpers/formatPostalCode';
import { formatPrice } from 'Helpers/formatPrice';
import {
    totalWeightForLineItem,
    totalWeightForLineItems,
} from 'Helpers/lineItemCalculations';
import { timeLabelForShipmentStateVerbose } from 'Helpers/timeLabelForShipmentState';
import moment from 'moment';
import { ReactNode } from 'react';
import styled from 'styled-components/macro';
import SmallFromTo from './SmallFromTo';

const State = styled.div`
    font-weight: var(--nhu-font-weight-medium);
    font-size: 16px;
    color: var(--freightsimple-color-normal-text);
`;

const StateSubtitle = styled.div`
    font-weight: var(--nhu-font-weight-bold);
    font-size: 12px;
    color: var(--freightsimple-color-light-text);
`;

const Payload = styled.div`
    font-weight: var(--nhu-font-weight-regular);
    font-size: 12px;
    color: var(--freightsimple-color-light-text);
`;

const TimeLabel = styled.div`
    font-weight: var(--nhu-font-weight-light);
    font-size: 10px;
    color: var(--freightsimple-color-light-text);
`;

interface TimeDescriptionProps {
    error: boolean;
}

const TimeDescription = styled.div<TimeDescriptionProps>`
    font-weight: var(--nhu-font-weight-medium);
    font-size: 14px;
    color: ${(props) =>
        props.error
            ? 'var(--freightsimple-color-red)'
            : 'var(--freightsimple-color-normal-text)'};
`;

const IconHoverWrapper = styled.div`
    &:hover svg {
        color: ${Colors.NormalText} !important;
    }
`;

function MissingCustomsDocsWarning() {
    return (
        <HorizontalStack style={{ width: '200px' }}>
            <Icon
                name="exclamation-triangle"
                color={Colors.Gold}
                size={16}
                solid
                style={{ marginLeft: '32px', marginRight: '8px' }}
            />
            <Legalese>Missing customs docs</Legalese>
        </HorizontalStack>
    );
}

interface DeleteButtonProps {
    onClick: () => Promise<void>;
}

function DeleteButton(props: DeleteButtonProps) {
    return (
        <LinkButton onClick={props.onClick}>
            <IconHoverWrapper>
                <Icon
                    name="trash"
                    color={Colors.LightGray}
                    size={16}
                    solid
                    style={{ marginLeft: '32px' }}
                />
            </IconHoverWrapper>
        </LinkButton>
    );
}

export function nameForShipmentState(
    state: ShipmentState,
    selectedQuote: Quote | undefined,
    cheapestQuote: Quote | undefined,
    manualQuotingOpen: boolean
): ReactNode {
    switch (state) {
        case ShipmentState.Quoted:
        case ShipmentState.QuoteRequested: {
            if (manualQuotingOpen) {
                return (
                    <HorizontalStack>
                        <div>Preparing Quotes</div>
                        <div>🦸‍♀️</div>
                    </HorizontalStack>
                );
            } else {
                if (selectedQuote) {
                    return `Quote`;
                } else if (cheapestQuote) {
                    return `Quotes`;
                } else {
                    return 'No Quotes';
                }
            }
        }

        case ShipmentState.BookingConfirmed:
            return 'Booked';
        case ShipmentState.BookingRequested:
        case ShipmentState.BookingFailed:
            return 'Preparing';
        case ShipmentState.InTransit:
            return 'In Transit';
        case ShipmentState.Delivered:
            return 'Delivered';
        case ShipmentState.Cancelled:
            return 'Cancelled';
        case ShipmentState.Lost:
            return `Lost`;
        case ShipmentState.OnHold:
            return `On Hold`;
    }
}

function makeTimeDescription(
    shipmentState: ShipmentState,
    pickupDateDescription: string | undefined,
    expectedDeliveryDateDescription: string | undefined,
    appointmentDescription: string | undefined,
    actualDeliveryDateDescription: string | undefined
): string {
    switch (shipmentState) {
        case ShipmentState.Quoted:
        case ShipmentState.QuoteRequested:
        case ShipmentState.BookingConfirmed:
        case ShipmentState.BookingRequested:
        case ShipmentState.BookingFailed:
            if (pickupDateDescription === undefined) {
                return 'Unknown';
            }
            return pickupDateDescription;
        case ShipmentState.InTransit:
            if (appointmentDescription !== undefined) {
                return appointmentDescription;
            } else if (expectedDeliveryDateDescription === undefined) {
                return 'Waiting for update';
            } else {
                return expectedDeliveryDateDescription;
            }

        case ShipmentState.Delivered:
            if (actualDeliveryDateDescription === undefined) {
                return 'Waiting for update';
            }
            return actualDeliveryDateDescription;
        case ShipmentState.Cancelled:
            return '';
        case ShipmentState.Lost:
            return '';
        case ShipmentState.OnHold:
            return 'On Hold';
    }
}

interface DashboardRowProps {
    shipmentState: ShipmentState;
    lineItems: Array<AddLineItemType | LineItem>;
    pickupLocation: AddLocationLocationType | Location;
    deliveryLocation: AddLocationLocationType | Location;
    pickupDate: string | undefined;
    pickupHours: Hours | undefined;
    appointmentDate: string | undefined;
    expectedDeliveryDate: string | undefined;
    expectedDeliveryHours: string | undefined;
    actualDeliveryDate: string | undefined;
    actualDeliveryTime: string | undefined;
    carrierIdentifier: string | undefined;
    onClick: () => void;
    selectedQuote?: Quote;
    cheapestQuote?: Quote;
    pickupReference: string;
    deliveryReference: string;
    onDelete?: () => Promise<void>;
    overrideLastColumnLabel?: string;
    overrideLastColumnDescription?: string;
    needsCustomsDocs?: boolean;
    manualQuotingOpen: boolean;
}

interface DescribeHandlingUnitsProps {
    lineItems: Array<LineItem | AddLineItemType>;
}

function DescribeHandlingUnits(props: DescribeHandlingUnitsProps) {
    function totalLineItemHandlingUnits(
        lineItems: Array<LineItem | AddLineItemType>
    ) {
        let totalHandlingUnits = 0;
        lineItems.forEach((li) => {
            totalHandlingUnits += li.numberHandlingUnits;
        });
        return totalHandlingUnits;
    }

    function handlingUnitForLineItems(
        lineItems: Array<LineItem | AddLineItemType>
    ) {
        let handlingUnitType: HandlingUnitType | undefined | 'Units' =
            undefined;
        lineItems.forEach((li) => {
            if (handlingUnitType === undefined) {
                handlingUnitType = li.handlingUnitType;
            }

            if (handlingUnitType !== li.handlingUnitType) {
                handlingUnitType = 'Units';
            }
        });

        return handlingUnitType;
    }

    const { lineItems } = props;
    if (lineItems.length === 1) {
        return (
            <Stack>
                <Payload>
                    {lineItems[0].numberHandlingUnits} x{' '}
                    {lineItems[0].handlingUnitType}
                </Payload>
                <Payload>{totalWeightForLineItem(lineItems[0])} lb</Payload>
            </Stack>
        );
    } else {
        return (
            <Stack>
                <Payload>
                    {totalLineItemHandlingUnits(lineItems)} x{' '}
                    {handlingUnitForLineItems(lineItems)}
                </Payload>
                <Payload>{totalWeightForLineItems(lineItems)} lb</Payload>
            </Stack>
        );
    }

    return <></>;
}

function DashboardRow(props: DashboardRowProps) {
    const {
        shipmentState,
        pickupLocation,
        deliveryLocation,
        pickupDate,
        pickupHours,
        appointmentDate,
        expectedDeliveryDate,
        expectedDeliveryHours,
        actualDeliveryDate,
        actualDeliveryTime,
        carrierIdentifier,
        onClick,
        selectedQuote,
        cheapestQuote,
        pickupReference,
        deliveryReference,
        lineItems,
        onDelete,
        needsCustomsDocs,
        manualQuotingOpen,
    } = props;

    const pickupCity = `${pickupLocation.address.city}, ${pickupLocation.address.stateOrProvinceCode}`;
    const deliveryCity = `${deliveryLocation.address.city}, ${deliveryLocation.address.stateOrProvinceCode}`;

    // If we don't have a business name, use the postal code
    const pickupBusinessName =
        pickupLocation.businessName ??
        (pickupLocation.address.postalCode
            ? `Picking up from : ${formatPostalCode(
                  pickupLocation.address.postalCode
              )}`
            : '');
    const deliveryBusinessName =
        deliveryLocation.businessName ??
        (deliveryLocation.address.postalCode
            ? `Delivering to : ${formatPostalCode(
                  deliveryLocation.address.postalCode
              )}`
            : '');

    const pickupDateDescription = describeDateRelativeWithTime(
        pickupDate,
        pickupHours ? describeHours(pickupHours) : undefined,
        false
    );

    const expectedDeliveryDateDescription = describeDateRelativeWithTime(
        expectedDeliveryDate,
        expectedDeliveryHours,
        true
    );

    const appointmentDescription = appointmentDate
        ? describeDateRelative(appointmentDate)
        : undefined;

    const actualDeliveryTimeDescription = actualDeliveryTime
        ? moment(actualDeliveryTime, 'HH:mm').format('h:mma')
        : undefined;

    const actualDeliveryDateDescription = describeDateRelativeWithTime(
        actualDeliveryDate,
        actualDeliveryTimeDescription,
        false
    );

    const timeDescription = makeTimeDescription(
        shipmentState,
        pickupDateDescription,
        expectedDeliveryDateDescription,
        appointmentDescription,
        actualDeliveryDateDescription
    );

    function getStateSubtitle() {
        if (shipmentState !== ShipmentState.Quoted) {
            return undefined;
        }

        if (selectedQuote) {
            return `Selected $${formatPrice(selectedQuote.price)}`;
        }

        if (cheapestQuote) {
            return `From $${formatPrice(cheapestQuote.price)}`;
        }

        // We could be in a situation with no quotes even after it's quoted
        return undefined;
    }

    const stateSubtitle = getStateSubtitle();

    const displayDateAsError =
        (props.shipmentState === ShipmentState.Quoted ||
            props.shipmentState === ShipmentState.QuoteRequested ||
            props.shipmentState === ShipmentState.BookingFailed) &&
        pickupDate !== undefined &&
        moment(pickupDate).isBefore(moment().startOf('day'));

    const showTime =
        shipmentState !== ShipmentState.Cancelled &&
        shipmentState !== ShipmentState.Lost;

    return (
        <div
            style={{ cursor: 'pointer', marginBottom: '16px' }}
            onClick={onClick}
        >
            <HorizontalStack>
                <Box
                    colorBar={colorForShipmentState(shipmentState)}
                    style={{ width: '1024px', height: '72px', padding: 0 }}
                    reactToHover={true}
                    boxStyle={BoxStyle.None}
                >
                    <HorizontalStack
                        verticalAlign="middle"
                        style={{ height: '100%' }}
                    >
                        <Stack
                            align="left"
                            style={{ paddingLeft: '32px', width: '150px' }}
                        >
                            <State>
                                {nameForShipmentState(
                                    shipmentState,
                                    selectedQuote,
                                    cheapestQuote,
                                    manualQuotingOpen
                                )}
                            </State>
                            {stateSubtitle && (
                                <StateSubtitle>{stateSubtitle}</StateSubtitle>
                            )}
                        </Stack>

                        <Stack
                            style={{
                                width: '70px',
                                paddingLeft: '8px',
                                marginRight: '16px',
                            }}
                        >
                            <DescribeHandlingUnits lineItems={lineItems} />
                        </Stack>

                        <SmallFromTo
                            pickupBusinessName={pickupBusinessName}
                            pickupCity={pickupCity}
                            pickupReference={pickupReference}
                            deliveryBusinessName={deliveryBusinessName}
                            deliveryCity={deliveryCity}
                            deliveryReference={deliveryReference}
                        />

                        <Stack align="left" style={{ width: '112px' }}>
                            {carrierIdentifier && (
                                <CarrierLogo
                                    align="center"
                                    carrierIdentifier={carrierIdentifier}
                                    width={108}
                                    height={34}
                                />
                            )}
                        </Stack>

                        {showTime && (
                            <div style={{ paddingLeft: '32px' }}>
                                {props.overrideLastColumnLabel !==
                                    undefined && (
                                    <Stack align="left">
                                        <TimeLabel>
                                            {props.overrideLastColumnLabel}
                                        </TimeLabel>
                                        <TimeDescription error={false}>
                                            {
                                                props.overrideLastColumnDescription
                                            }
                                        </TimeDescription>
                                    </Stack>
                                )}
                                {props.overrideLastColumnLabel ===
                                    undefined && (
                                    <Stack align="left">
                                        <TimeLabel>
                                            {timeLabelForShipmentStateVerbose(
                                                shipmentState,
                                                appointmentDescription
                                            )}
                                        </TimeLabel>
                                        <TimeDescription
                                            error={displayDateAsError}
                                        >
                                            {timeDescription}
                                        </TimeDescription>
                                    </Stack>
                                )}
                            </div>
                        )}
                    </HorizontalStack>
                </Box>

                {shipmentState === ShipmentState.Quoted && onDelete && (
                    <DeleteButton onClick={onDelete} />
                )}
                {shipmentState === ShipmentState.BookingConfirmed &&
                    needsCustomsDocs && <MissingCustomsDocsWarning />}
            </HorizontalStack>
        </div>
    );
}
export default DashboardRow;
