import { useCustomsDocsApi } from 'apis';
import Colors from 'Cargo/Colors';
import Button from 'Cargo/Controls/Button';
import Link from 'Cargo/Controls/Link';
import Icon from 'Cargo/Icons/Icon';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import { H3, Legalese, Microcopy } from 'Cargo/Text/Text';
import { UUID } from 'Cargo/Types/types';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components/macro';
import ViewShipmentWarningSection from './ViewShipmentWarningSection';

interface CustomsDocsSectionProps {
    shipmentId: UUID;
    onUploaded: () => void;
}

interface DropContainerProps {
    isDragAccept: boolean;
    isDragReject: boolean;
    isDragActive: boolean;
}

function getColor(props: DropContainerProps) {
    if (props.isDragAccept) {
        return '#00e676';
    }
    if (props.isDragReject) {
        return '#ff1744';
    }
    if (props.isDragActive) {
        return '#2196f3';
    }
    return '#ccc';
}

const DropContainer = styled.div<DropContainerProps>`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 5px;
    border-radius: 20px;
    border-color: ${(props) => getColor(props)};
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;
    width: 480px;
    margin-bottom: 32px;
`;

export function CustomsDocsSection(props: CustomsDocsSectionProps) {
    const customsDocsApi = useCustomsDocsApi();

    const [loading, setLoading] = useState(false);
    const [loadingSuccess, setLoadingSuccess] = useState(false);
    const [loadingFailure, setLoadingFailure] = useState(false);

    const [files, setFiles] = useState<Array<File>>([]);
    const onDrop = function (acceptedFiles: Array<File>) {
        setFiles([...files, ...acceptedFiles]);
    };
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        onDrop,
        maxFiles: 10,
        accept: 'application/pdf',
    });

    async function onUpload() {
        setLoadingSuccess(false);
        setLoadingFailure(false);
        setLoading(true);
        try {
            await customsDocsApi.upload(props.shipmentId, files);
            setLoadingSuccess(true);
            setTimeout(function () {
                props.onUploaded();
            }, 1000);
        } catch (e) {
            console.error(`Failure uploading customs docs`, { e });
            setLoadingFailure(true);
        } finally {
            setLoading(false);
        }
    }

    function acceptedFilesCount() {
        if (files.length === 0) {
            return '';
        }

        if (files.length === 1) {
            return ' (1 file)';
        }

        if (files.length > 1) {
            return ` (${files.length} file)`;
        }
    }

    return (
        <ViewShipmentWarningSection
            title={
                <HorizontalStack>
                    <span>Next: Upload Customs Documents</span>
                </HorizontalStack>
            }
            subtitle={
                <>
                    <p>
                        FreightSimple will send your customs documents to the
                        carrier to ensure there are no delays. These{' '}
                        <strong>MUST</strong> be submitted before pickup as some
                        carriers will not pick up the freight until they receive
                        these documents.
                    </p>
                    <p>
                        If you're not sure what documents you need, or to learn
                        more please see our{' '}
                        <Link
                            to="https://help.freightsimple.com/using-freightsimple/booking-your-shipment/uploading-your-customs-documents"
                            newTab
                        >
                            Help Center
                        </Link>
                    </p>
                </>
            }
            id="next-steps-section"
        >
            <Stack width="100%" align="center">
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <DropContainer
                    {...getRootProps({
                        isDragActive,
                        isDragAccept,
                        isDragReject,
                    })}
                >
                    <input {...getInputProps()} />

                    <Stack>
                        <Spacer height={32} />
                        <H3>Customs Documents PDF files</H3>
                        <Microcopy>
                            Drag and drop or click to choose files to upload
                        </Microcopy>

                        <Spacer height={16} />
                        {files.length === 0 && (
                            <Icon
                                name="file-pdf"
                                color="#ccc"
                                size={64}
                                solid
                            />
                        )}


                        <Stack align="left">
                            {files.map(function (af) {
                                return (
                                    <HorizontalStack key={af.name}>
                                        <Icon
                                            name="file-pdf"
                                            color={Colors.Blue}
                                            size={16}
                                            solid
                                            style={{ marginRight: '4px' }}
                                        />

                                        <Legalese>{af.name}</Legalese>
                                    </HorizontalStack>
                                );
                            })}
                        </Stack>
                        <Spacer height={16} />
                        <Legalese>
                            Multiple files allowed
                        </Legalese>
                        <Spacer height={16} />
                    </Stack>
                </DropContainer>
                {files.length !== 0 && (
                    <Button
                        size="large"
                        onClick={onUpload}
                        loadingSuccess={loadingSuccess}
                        loading={loading}
                        loadingFailure={loadingFailure}
                        disabled={loading || loadingSuccess}
                    >
                        Upload Customs Docs {acceptedFilesCount()}
                    </Button>
                )}
            </Stack>
        </ViewShipmentWarningSection>
    );
}
