import React, { ReactNode } from 'react';
import { animated, config, useSpring } from '@react-spring/web';
import { useHeight } from 'Cargo/Helpers/useHeight';

interface AnimatedContainerProps {
    open: boolean;
    children: ReactNode;
}

export function AnimatedContainer(props: AnimatedContainerProps) {
    const [heightRef, height] = useHeight();
    const slideInStyles = useSpring({
        config: { ...config.default },
        from: { height: '0px', opacity: 0 },
        to: {
            height: props.open ? height + 8 + 'px' : '0',
            opacity: props.open ? 1 : 0,
        },
    });
    return (
        <animated.div
            style={{
                ...slideInStyles,
                overflow: 'hidden',
                width: '100%',
                border: `1px solid #bbb`,
            }}
        >
            <div ref={heightRef}>{props.children}</div>
        </animated.div>
    );
}
