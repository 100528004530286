import Dropdown from 'Cargo/Controls/Dropdown';
import { FreightClaimType } from 'generated-openapi-client';

interface ClaimTypeDropdownProps {
    claimType: FreightClaimType;
    setClaimType: (_: FreightClaimType) => void;
}

export function ClaimTypeDropdown(props: ClaimTypeDropdownProps) {
    const { claimType, setClaimType } = props;
    return (
        <Dropdown<FreightClaimType>
            selectedItem={claimType}
            onOptionSelected={setClaimType}
            options={[
                {
                    item: FreightClaimType.NoDamageOrLoss,
                    description: '👍 No Damage or Loss',
                },
                {
                    item: FreightClaimType.Damage,
                    description: '❌ Damage',
                },
                {
                    item: FreightClaimType.Loss,
                    description: '❌ Loss',
                },
            ]}
            width={300}
            errorMessage={undefined}
            forceValidation={false}
        ></Dropdown>
    );
}
