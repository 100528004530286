import { ErrorMessageType } from 'Cargo/Validation';
import {
    convertFromUnit,
    convertToUnit,
    Dimension,
    Units,
} from 'Helpers/units';
import React from 'react';
import Input from './Input';

interface NumberInputProps {
    id?: string;
    label?: string;
    autoComplete?: string;
    value?: number;
    onChange?: (e: number | undefined) => void;
    name?: string;
    enabled?: boolean;
    autoFocus?: boolean;
    required?: boolean;
    pattern?: string;
    readOnly?: boolean;
    hidden?: boolean;
    width?: number;
    errorMessage: ErrorMessageType;
    forceValidation: boolean;
    hideErrorIcon?: boolean;
    labelPadding?: number;
    validateOnTimeoutMs?: number;
    onBlur?: (e: React.FormEvent<HTMLInputElement>) => void;
    units: Units;
    dimension: Dimension;
}

const NumberInput: React.FC<NumberInputProps> = (props: NumberInputProps) => {
    function render() {
        const output = convertToUnit(props.value, props.units, props.dimension);

        const truncated = output?.toString();

        console.log(`render ${props.value} -> ${output} -> ${truncated}`);
        return truncated;
    }
    return (
        <Input
            id={props.id}
            label={props.label}
            autoComplete={props.autoComplete}
            value={render()}
            onChange={(value) => {
                props.onChange?.(
                    value === ''
                        ? undefined
                        : convertFromUnit(
                              parseInt(value),
                              props.units,
                              props.dimension
                          )
                );
            }}
            name={props.name}
            enabled={props.enabled}
            autoFocus={props.autoFocus}
            required={props.required}
            readOnly={props.readOnly}
            hidden={props.hidden}
            width={props.width}
            type="number"
            errorMessage={props.errorMessage}
            forceValidation={props.forceValidation}
            hideErrorIcon={props.hideErrorIcon}
            labelPadding={props.labelPadding}
            validateOnTimeoutMs={props.validateOnTimeoutMs}
            onBlur={props.onBlur}
        />
    );
};
export default NumberInput;
