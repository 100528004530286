import { DocumentType } from 'generated-openapi-client';

export function fileNameForDocumentType(documentType: DocumentType): string {
    switch (documentType) {
        case DocumentType.BillOfLading:
            return 'bill-of-lading.pdf';
        case DocumentType.CargoClaimsForm:
            return 'cargo-claims-form.pdf';
        case DocumentType.CarrierScannedBillOfLading:
            return 'carrier-scanned-bill-of-pading.pdf';
        case DocumentType.CarrierWeightReport:
            return 'carrier-weight-report.pdf';
        case DocumentType.CertificateOfOrigin:
            return 'certificate-of-origin.pdf';
        case DocumentType.CommercialInvoice:
            return 'commercial-invoice.pdf';
        case DocumentType.DangerousGoodsForm:
            return 'dangerous-goods-form.pdf';
        case DocumentType.ExportLicense:
            return 'export-license.pdf';
        case DocumentType.InsuranceCertificate:
            return 'insurance-certificate.pdf';
        case DocumentType.ShipperPhoto:
            return 'pickup-photo.pdf';
        case DocumentType.PackingList:
            return 'packing-list.pdf';
        case DocumentType.ProofOfDelivery:
            return 'proof-of-delivery.pdf';
        case DocumentType.PurchaseOrder:
            return 'purchase-order.pdf';
        case DocumentType.CustomsDocument:
            return 'customs-document.pdf';
        case DocumentType.PickupPackage:
            return 'pickup-package.pdf';
        case DocumentType.Image:
            return 'image.pdf';
        case DocumentType.Unknown:
            // Shouldn't ever be here
            return 'unknown.pdf';
        case DocumentType.ClaimsOriginalInvoice:
            return 'claim-original-invoice.pdf';
        case DocumentType.ClaimsPhoto:
            return 'claim-photo.pdf';
        case DocumentType.ClaimsOtherDocument:
            return 'claim-other-document.pdf';
        case DocumentType.InterlineBillOfLading:
            return 'interline-bill-of-lading.pdf';
        case DocumentType.EmailAttachment:
            return 'email-attachment.pdf';
        case DocumentType.SentEmailAttachment:
            return 'email-attachment.pdf';
        case DocumentType.CarrierEmailAttachment:
            return 'email-attachment.pdf';
        case DocumentType.CustomerEmailAttachment:
            return 'email-attachment.pdf';
    }
}
