import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accessorials } from 'apis';
import Colors from 'Cargo/Colors';
import Tooltip from 'Cargo/Controls/Tooltip';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import useConfirmModal from 'Cargo/Modal/useConfirmModal';
import { AccessorialType } from 'Data/AccessorialTypes';
import { LocationContext, LocationType } from 'generated-openapi-client';
import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components/macro';

interface DescribeChallengeRemovalTemplateProps {
    line1: string;
    line2: string;
}

function DescribeChallengeRemovalTemplate(props: DescribeChallengeRemovalTemplateProps) {
    return <Stack align="left">
        <div>{props.line1}</div>
        <Spacer height={16} />
        <div>{props.line2}</div>
        <Spacer height={16} />
        <div>Are you sure you want to remove this?</div>
    </Stack>
}

type AccessorialsInputProps = {
    accessorials: Array<AccessorialType>;
    selectedAccessorials: Accessorials;
    selectedAccessorialsChanged: (_: Accessorials) => void;
    locationContext: LocationContext;
    locationType: LocationType
};

interface ContainerProps {
    comingSoon: boolean | undefined;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Container = styled(({ comingSoon, ...rest }) => (
    <div {...rest} />
)) <ContainerProps>`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    cursor: ${(props) => (props.comingSoon ? 'default' : 'pointer')};
    width: 348px;
`;

const Header = styled.h3`
    font-weight: var(--nhu-font-weight-regular);
    font-size: 16px;
    color: var(--freightsimple-color-normal-text);
    margin-bottom: 1px;
`;

const Description = styled.p`
    font-weight: var(--nhu-font-weight-light);
    font-size: 12px;
    color: var(--freightsimple-color-light-text);
`;

const CheckBoxBox = styled.div`
    border: 1px solid #cbcbcb;
    width: 32px;
    height: 32px;
    padding: 6px;
    position: relative;
    top: -8px;
`;

function checkBox(isChecked: boolean, hover: boolean): ReactElement {
    return (
        <CheckBoxBox>
            {isChecked && (
                <FontAwesomeIcon
                    icon={['fas', 'check']}
                    style={{
                        width: '20px',
                        height: '20px',
                        color: Colors.Blue,
                    }}
                />
            )}
            {!isChecked && hover && (
                <FontAwesomeIcon
                    icon={['fas', 'check']}
                    style={{
                        width: '20px',
                        height: '20px',
                        color: Colors.LightBlue,
                        opacity: '0.5',
                    }}
                />
            )}
        </CheckBoxBox>
    );
}

const AccessorialsInput: React.FC<AccessorialsInputProps> = (
    props: AccessorialsInputProps
) => {
    const [selectedAccessorials, setSelectedAccessorials] = useState<
        Array<string>
    >(props.selectedAccessorials);

    useEffect(
        function () {
            setSelectedAccessorials(props.selectedAccessorials);
        },
        [props.selectedAccessorials]
    );



    function isAccessorialSelected(type: string): boolean {
        const isSelected = selectedAccessorials.includes(type);
        console.log(
            `!!!!! isAccessorialSelected`,
            isSelected,
            type,
            selectedAccessorials
        );
        return isSelected;
    }

    function shouldChallengeRemoval(type: string): boolean {
        if (props.locationType === LocationType.DistributionWarehouse) {
            // Appointments are strongly recommended
            if (type == "SCHEDULING_APPOINTMENT_REQUIRED") {
                return true
            }
        }

        if (props.locationType === LocationType.Residential) {
            // Appointments and liftgates are strongly recommended
            if (type == "SCHEDULING_APPOINTMENT_REQUIRED") {
                return true
            }

            if (type == "LOGISTICS_LIFT_GATE_REQUIRED") {
                return true
            }
        }

        return false
    }



    function describeChallengeRemoval(type: string): ReactNode | undefined {
        if (props.locationType === LocationType.DistributionWarehouse) {
            // Appointments are strongly recommended
            if (type == "SCHEDULING_APPOINTMENT_REQUIRED") {
                return <DescribeChallengeRemovalTemplate line1="Most distribution warehouses have strict requirements about appointments." line2="Additional charges may apply later if the carrier is required to make an appointment" />
            }
        }

        if (props.locationType === LocationType.Residential) {
            // Appointments and liftgates are strongly recommended
            if (type == "SCHEDULING_APPOINTMENT_REQUIRED") {
                return <DescribeChallengeRemovalTemplate line1="Most residential deliveries require appointments to ensure the resident is at home." line2="Additional charges may apply later if the carrier is required to make an appointment" />
            }

            if (type == "LOGISTICS_LIFT_GATE_REQUIRED") {
                if (props.locationContext === LocationContext.Delivery) {
                    return <DescribeChallengeRemovalTemplate line1="Most residential deliveries require liftgates to offload the freight as most residences do not have loading docks" line2="Additional charges may apply later if the carrier is required to use a liftgate to complete the delivery" />
                } else {
                    return <DescribeChallengeRemovalTemplate line1="Most residential pickups require liftgates to offload the freight as most residences do not have loading docks" line2="Additional charges may apply later if the carrier is required to use a liftgate to complete the pickup" />

                }
            }
        }

        return undefined
    }

    const confirmRemoval = useConfirmModal('Confirm Special Service Removal')


    async function handleRowClick(
        event: React.MouseEvent<HTMLDivElement>,
        type: string
    ): Promise<void> {
        event.preventDefault();

        const isRemoval = selectedAccessorials.includes(type)
        let allowedToProceed = true
        if (isRemoval && shouldChallengeRemoval(type)) {
            allowedToProceed = await confirmRemoval(describeChallengeRemoval(type))
        }

        if (allowedToProceed) {
            let newValue;

            if (selectedAccessorials.includes(type)) {
                newValue = selectedAccessorials.filter((o) => o !== type);
            } else {
                newValue = [...selectedAccessorials, type];
            }

            setSelectedAccessorials(newValue);

            props.selectedAccessorialsChanged(newValue);
        }
    }

    return (
        <>
            {props.accessorials.map((accessorial, i) => (
                <AccessorialContainer
                    key={i}
                    accessorial={accessorial}
                    handleRowClick={handleRowClick}
                    isAccessorialSelected={isAccessorialSelected}
                    locationContext={props.locationContext}
                />
            ))}
        </>
    );
};

export default AccessorialsInput;

interface AccessorialContainerProps {
    accessorial: AccessorialType;
    handleRowClick: (
        event: React.MouseEvent<HTMLDivElement>,
        type: string
    ) => void;
    isAccessorialSelected: (_: string) => boolean;
    locationContext: LocationContext;
}

function AccessorialContainer(props: AccessorialContainerProps): JSX.Element {
    const [hover, setHover] = useState(false);

    function TooltipWrapper(children: ReactNode): JSX.Element {
        if (props.accessorial.comingSoon) {
            return <Tooltip tooltip="Coming soon">{children}</Tooltip>;
        } else {
            return <>{children}</>;
        }
    }

    return TooltipWrapper(
        <div style={{ opacity: props.accessorial.comingSoon ? 0.4 : 1.0 }}>
            <Container
                onClick={(e: React.MouseEvent<HTMLDivElement>): void => {
                    if (props.accessorial.comingSoon) {
                        return;
                    }
                    props.handleRowClick(e, props.accessorial.type);
                }}
                onMouseEnter={() => {
                    if (props.accessorial.comingSoon) {
                        return;
                    }
                    setHover(true);
                }}
                onMouseLeave={() => {
                    if (props.accessorial.comingSoon) {
                        return;
                    }
                    setHover(false);
                }}
                comingSoon={props.accessorial.comingSoon}
            >
                <div data-accessorial={props.accessorial.type}>
                    <Header>
                        {props.accessorial.name}
                        {props.accessorial.questionBubble(
                            props.locationContext
                        )}
                    </Header>
                    <Description>{props.accessorial.description}</Description>
                </div>
                <div>
                    {checkBox(
                        props.isAccessorialSelected(props.accessorial.type),
                        hover
                    )}
                </div>
            </Container>
        </div>
    );
}
