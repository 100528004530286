import Animation from 'Cargo/Animations/Animation';
import SlideDown from 'Cargo/Animations/SlideDown';
import Colors from 'Cargo/Colors';
import Button from 'Cargo/Controls/Button';
import LinkButton from 'Cargo/Controls/LinkButton';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import useConfirmModal from 'Cargo/Modal/useConfirmModal';
import { ErrorMessage, H0, Legalese, Microcopy } from 'Cargo/Text/Text';
import { UUID } from 'Cargo/Types/types';
import { useAuthentication } from 'Features/Authentication/Slices/authenticationSlice';
import {
    LineItemErrors,
    errorMessagesForLineItem,
} from 'Features/LineItems/Validators/errorMessagesForLineItem';
import { errorMessageForBranches } from 'Features/Locations/Validators/errorMessageForBranches';
import { errorMessageForInsurance } from 'Features/Locations/Validators/errorMessageForInsurance';
import {
    errorMessagesForDeliveryLocation,
    warningMessagesForDeliveryLocation,
} from 'Features/Locations/Validators/errorMessagesForDeliveryLocation';
import {
    errorMessagesForPickupLocation,
    warningMessagesForPickupLocation,
} from 'Features/Locations/Validators/errorMessagesForPickupLocation';
import { isFreightClassRequired } from 'Helpers/isFreightClassRequired';
import {
    totalHandlingUnitsForLineItems,
    totalWeightForLineItems,
} from 'Helpers/lineItemCalculations';
import { useOnce } from 'Hooks/useOnce';
import { useShipmentService2 } from 'Services/ShipmentService2';
import { useSavedLocationsApi } from 'apis';
import { isHolidayForCountry } from 'freightsimple-utilities/dist/holidays/moment';
import {
    LocationContext,
    SavedCommodity,
    SavedLocation,
} from 'generated-openapi-client';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { AddLineItemType } from '../LineItems/Types/lineItemTypes';
import { BookShipmentDetailsErrorWarning } from './Components/BookShipmentDetailsErrorWarning';
import { BookShipmentWarningMessages } from './Components/BookShipmentWarningMessages';
import BranchesSection from './Components/BranchesSection';
import { InsuranceSection } from './Components/InsuranceSection';
import LocationSection2 from './Components/Location/LocationSection2';
import { MemoizedPickupDateSection } from './Components/PickupDateSection';
import ReferenceNumbersSection from './Components/ReferenceNumbersSection';
import ShipmentContentsSection2 from './Components/ShipmentContentsSection2';
import {
    isWeekend,
    nextBusinessDay,
    nextBusinessDayForCountry,
} from './Helpers/nextBusinessDay';
import { useBookShipmentWarningMessages } from './Hooks/useBookShipmentWarningMessages';
import { useAuthenticateForQuotesModal } from './Modals/useAuthenticateForQuotesModal';
import {
    addLineItem,
    addLineItemFromSavedCommodity,
    newDeliveryLocation,
    newPickupLocation,
    onAddInsuranceToShipmentChanged,
    onSetInsuranceAmount,
    onSetInsuranceCurrency,
    rearrangeLengthWidth,
    removeLineItem,
    resetShipment,
    setBranchId,
    setDeliveryLocation,
    setDeliveryReferenceNumber,
    setEnteringNewDelivery,
    setEnteringNewPickup,
    setPickupDate,
    setPickupLocation,
    setPickupReferenceNumber,
    setSelectedDeliverySavedLocationId,
    setSelectedPickupSavedLocationId,
    updateLineItem,
    useBookShipment2Slice,
} from './Slices/bookShipmentSlice';


interface BookShipmentDetailsScreenProps {
    savedLocations: Array<SavedLocation>;
    savedCommodities: Array<SavedCommodity>;
    defaultPickupLocationId: UUID | undefined;
    defaultDeliveryLocationId: UUID | undefined;
}

function BookShipmentDetailsScreen(props: BookShipmentDetailsScreenProps) {
    console.log('BookShipmentDetailsScreen', { props });
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [submittingQuotes, setSubmittingQuotes] = useState(false);
    const [forceValidation, setForceValidation] = useState(false);
    const [nmfcErrors, setNmfcErrors] = useState(new Set<UUID>());
    const [isPickupDateHoliday, setIsPickupDateHoliday] = useState(false);
    const [isPickupDateWeekend, setIsPickupDateWeekend] = useState(false);
    const datePickerRef = useRef<HTMLDivElement>(null);

    // Autocomplete in Chrome when creating an account can interfere with the entered
    // postal codes - so we want to track when the create account popup is showing
    // and disable postal code inputs
    const [showingCreateAccountPopup, setShowingCreateAccountPopup] =
        useState(false);
    const { isAuthenticated } = useAuthentication();
    const shipmentService = useShipmentService2();
    const authenticate = useAuthenticateForQuotesModal();
    const [quotesRequested, setQuotesRequested] = useState(false);
    const [quoteRequestError, setQuoteRequestError] = useState(false);
    const {
        savedLocations,
        savedCommodities,
        defaultPickupLocationId,
        defaultDeliveryLocationId,
    } = props;

    const [branches, setBranches] = useState<SavedLocation[]>([]);
    const [currentUserBranchId, setCurrentUserBranchId] = useState<
        UUID | undefined
    >();
    const savedLocationApi = useSavedLocationsApi();

    async function loadBranches() {
        if (isAuthenticated) {
            const response = await savedLocationApi.getBranches();
            setBranches(response.items);
            setCurrentUserBranchId(response.currentUserBranchId);
        }
    }

    useOnce(loadBranches);

    const {
        shipment,
        selectedPickupSavedLocationId,
        selectedDeliverySavedLocationId,
        enteringNewPickup,
        enteringNewDelivery,
    } = useBookShipment2Slice();
    console.log(`!!!!! shipment`, { shipment });
    const {
        pickupLocation,
        deliveryLocation,
        pickupDate,
        lineItems,
        pickupReferenceNumber,
        deliveryReferenceNumber,
        pickupContact,
        deliveryContact,
        addInsuranceToShipment,
        insuranceAmount,
        insuranceCurrency,
        branchId,
        deliveryDeadline,
        pickupDeadline,
    } = shipment;
    ////////////////////////////////////////////////////////////

    let i = 1;

    const memoSetPickupDate = useCallback(
        (newPickupDate) => {
            dispatch(setPickupDate(newPickupDate.format('YYYY-MM-DD')));
        },
        [dispatch]
    );

    const memoPickupDate = useMemo(() => moment(pickupDate), [pickupDate]);

    const errorMessagesForPickup = errorMessagesForPickupLocation(
        pickupDate,
        pickupLocation
    );
    const errorMessagesForDelivery = errorMessagesForDeliveryLocation(
        pickupLocation,
        deliveryLocation
    );

    const warningMessagesForPickup =
        warningMessagesForPickupLocation(pickupLocation);
    const warningMessagesForDelivery = warningMessagesForDeliveryLocation(
        pickupLocation,
        deliveryLocation
    );

    const totalWeightOfShipment = totalWeightForLineItems(lineItems);
    const totalHandlingUnitsOfShipment =
        totalHandlingUnitsForLineItems(lineItems);

    const errorMessagesForLineItems = new Map<string, LineItemErrors>();
    const freightClassRequired = isFreightClassRequired(
        pickupLocation,
        deliveryLocation
    );

    const confirmReset = useConfirmModal(
        'Reset Details',
        'Are you sure you want to reset?'
    );

    useEffect(
        function () {
            if (quotesRequested && isAuthenticated) {
                onGetQuotes();
            }
        },
        [quotesRequested, isAuthenticated]
    );

    useEffect(
        function () {
            console.log(`@@@ maybe setting branch id`, {
                shipmentId: shipment.shipmentId,
                isAuthenticated,
            });
            if (isAuthenticated) {
                console.log(`@@@ setting branch id : ${currentUserBranchId}`);
                dispatch(setBranchId(currentUserBranchId));
            }
        },
        [shipment.shipmentId, currentUserBranchId, isAuthenticated]
    );

    lineItems.forEach(function (li) {
        errorMessagesForLineItems.set(
            li.lineItemId,
            errorMessagesForLineItem(
                pickupDate,
                li,
                totalWeightOfShipment,
                totalHandlingUnitsOfShipment,
                freightClassRequired
            ).errorMessages
        );
    });

    const insuranceErrorMessage = errorMessageForInsurance(
        addInsuranceToShipment,
        insuranceAmount,
        pickupLocation,
        deliveryLocation
    );

    const branchesErrorMessage = errorMessageForBranches(branches, branchId);

    console.log(`@@@ Rendering`, {
        lineItems,
        errorMessagesForLineItems,
        branchesErrorMessage,
    });

    useEffect(
        function () {
            // If there are no saved commodities, and there are no line items,
            // then make a line item
            if (lineItems.length === 0 && savedCommodities.length === 0) {
                dispatch(addLineItem());
            }
        },
        [shipment.shipmentId]
    );

    const overallLineItemsErrorMessage =
        lineItems.length === 0
            ? 'Please add some contents to the shipment'
            : undefined;

    function validateDate() {
        const today = moment().startOf('day');
        const startOfDate = moment(pickupDate).clone().startOf('day');

        // Do not allow quotes to be run for pickups in the past
        // The control doesn't allow picking this, but you could modify a quote that was run a few days
        // ago and end up in this situation
        if (startOfDate.isBefore(today)) {
            return false;
        }

        // TODO: We need more sophisticated validation

        return true;
    }

    function validate() {
        // Check the date is valid
        if (!validateDate()) {
            return false;
        }

        if (lineItems.length === 0) {
            return false;
        }

        if (nmfcErrors.size > 0) {
            return false;
        }

        if (
            !lineItems.every(
                (li) =>
                    errorMessagesForLineItem(
                        pickupDate,
                        li,
                        totalWeightOfShipment,
                        totalHandlingUnitsOfShipment,
                        isFreightClassRequired(pickupLocation, deliveryLocation)
                    ).isValid
            )
        ) {
            return false;
        }

        if (errorMessagesForPickup.address.postalCode) {
            return false;
        }

        if (errorMessagesForPickup.locationType) {
            return false;
        }

        if (errorMessagesForDelivery.address.postalCode) {
            return false;
        }

        if (errorMessagesForDelivery.locationType) {
            return false;
        }

        if (insuranceErrorMessage !== undefined) {
            return false;
        }

        if (branchesErrorMessage !== undefined) {
            return false;
        }

        return true;
    }

    const isValid = validate();

    const { warnings } = useBookShipmentWarningMessages({
        pickupDate,
        lineItems,
        pickupLocation,
        deliveryLocation,
    });

    /*
     * Determine if the pickup date is a holiday based on the pickup location
     *
     * If it's a holiday the user should not get quotes
     */
    useEffect(() => {
        const pickupCountry = pickupLocation.address.countryCode;

        if (pickupCountry && pickupDate) {
            const response = isHolidayForCountry(
                moment(pickupDate),
                pickupCountry
            );
            setIsPickupDateHoliday(response);
        }
    }, [pickupLocation, pickupDate]);

    useEffect(() => {
        setIsPickupDateWeekend(isWeekend(moment(pickupDate)));
    }, [pickupDate]);

    function scrollToPickupDate() {
        if (datePickerRef && datePickerRef.current) {
            datePickerRef.current.scrollIntoView();

            const pickupCountry = shipment.pickupLocation.address.countryCode;

            let newDate = nextBusinessDay();

            if (pickupCountry) {
                newDate = nextBusinessDayForCountry(pickupCountry);
            }

            dispatch(setPickupDate(newDate.format('YYYY-MM-DD')));
        }
    }

    async function onReset() {
        const confirmed = await confirmReset();

        if (confirmed) {
            dispatch(
                resetShipment({
                    savedLocations,
                    defaultPickupLocationId,
                    defaultDeliveryLocationId,
                })
            );
            window.scrollTo(0, 0);
        }
    }

    function checkAuthenticated() {
        return isAuthenticated;
    }

    async function onGetQuotes() {
        console.log(
            `!!!! onGetQuotes - ${isAuthenticated} - checkAuthenticated=${checkAuthenticated()}`
        );
        if (!isValid) {
            setForceValidation(true);
            return;
        }

        setSubmittingQuotes(true);

        const newShipmentId = uuidv4();

        // If the dimensions were entered in cm/kg
        // then perhaps we have a lot of decimal places laying around
        // so need to round up to the nearest in/lb
        function roundUpDimension(value: number | undefined) {
            if (value === undefined) {
                return value;
            }

            return Math.ceil(value);
        }
        function roundDimensions(lineItem: AddLineItemType): AddLineItemType {
            // Basically overwrite the dimensions with rounded versions
            return {
                ...lineItem,
                length: roundUpDimension(lineItem.length),
                width: roundUpDimension(lineItem.width),
                height: roundUpDimension(lineItem.height),
                weightPerHandlingUnit: roundUpDimension(
                    lineItem.weightPerHandlingUnit
                ),
            };
        }

        try {
            // Create shipment and start getting quotes
            await shipmentService.createShipment(
                newShipmentId,
                pickupDate,
                pickupLocation,
                deliveryLocation,
                pickupContact,
                deliveryContact,
                lineItems.map(roundDimensions),
                pickupReferenceNumber,
                deliveryReferenceNumber,
                addInsuranceToShipment,
                insuranceAmount,
                insuranceCurrency,
                branchId,
                deliveryDeadline,
                pickupDeadline
            );

            setSubmittingQuotes(false);

            // Navigate to view the quotes
            // I think this double navigate is to get back button functionality working correctly
            navigate(`/book/details?shipmentId=${newShipmentId}`, {
                replace: true,
            });
            navigate(`/book/quotes?shipmentId=${newShipmentId}`);
        } catch (error) {
            /*
             * Stops spinning animation
             */
            setSubmittingQuotes(false);
            /*
             * Set quotes requested to false because there was an error when requesting
             * If this is not change to `false` the button won't send a request for quoting
             */
            setQuotesRequested(false);
            setQuoteRequestError(true);
        }
    }

    async function onGetQuotesOrCreateAccount() {
        setQuoteRequestError(false);
        console.log('onGetQuotesOrCreateAccount');
        if (!isValid) {
            console.log(
                'onGetQuotesOrCreateAccount  - not valid, returning early'
            );
            setForceValidation(true);
            return;
        }

        if (!isAuthenticated) {
            console.log('onGetQuotesOrCreateAccount  - not authenticated');
            setShowingCreateAccountPopup(true);
            const isNowAuthenticated = await authenticate();
            setShowingCreateAccountPopup(false);

            console.log(
                `onGetQuotesOrCreateAccount  - back from authenticate : ${isNowAuthenticated}`
            );

            if (!isNowAuthenticated) {
                console.log(
                    'onGetQuotesOrCreateAccount - isNowAuthenticated is false returning early'
                );
                return;
            }
        }

        console.log('onGetQuotesOrCreateAccount  - setQuotesRequested to true');
        setQuotesRequested(true);
    }

    function onSelectPickupFromAddressBook() {
        function updateForId(savedLocationId: UUID) {
            const savedLocation = savedLocations.find(
                (sl) => sl.savedLocationId === savedLocationId
            );

            if (savedLocation !== undefined) {
                dispatch(setEnteringNewPickup(false));
                dispatch(
                    setSelectedPickupSavedLocationId(
                        savedLocation.savedLocationId
                    )
                );
                dispatch(setPickupLocation(savedLocation.location));
            }
        }

        if (defaultPickupLocationId !== undefined) {
            if (defaultPickupLocationId !== undefined) {
                return updateForId(defaultPickupLocationId);
            }
        }

        const favouriteLocations = savedLocations.filter(
            (sl) => sl.isFavourite
        );
        if (favouriteLocations.length > 0) {
            return updateForId(favouriteLocations[0].savedLocationId);
        }

        return updateForId(savedLocations[0].savedLocationId);
    }

    function onSelectDeliveryFromAddressBook() {
        function updateForId(savedLocationId: UUID) {
            const savedLocation = savedLocations.find(
                (sl) => sl.savedLocationId === savedLocationId
            );

            if (savedLocation !== undefined) {
                dispatch(setEnteringNewDelivery(false));
                dispatch(
                    setSelectedDeliverySavedLocationId(
                        savedLocation.savedLocationId
                    )
                );
                console.log(
                    'onSelectDeliveryFromAddressBook - setDeliveryLocation'
                );
                dispatch(setDeliveryLocation(savedLocation.location));
            }
        }

        if (defaultDeliveryLocationId !== undefined) {
            if (defaultDeliveryLocationId !== undefined) {
                return updateForId(defaultDeliveryLocationId);
            }
        }

        const favouriteLocations = savedLocations.filter(
            (sl) => sl.isFavourite
        );
        if (favouriteLocations.length > 0) {
            return updateForId(favouriteLocations[0].savedLocationId);
        }

        return updateForId(savedLocations[0].savedLocationId);
    }

    function onAddLineItemFromSavedCommodity(sc: SavedCommodity) {
        dispatch(addLineItemFromSavedCommodity(sc));
    }

    function getTitle() {
        return 'Get Instant Quotes';
    }

    function getDescription() {
        return 'Enter your shipment details to get accurate quotes from dozens of the best carriers in North America.';
    }

    const showErrorsAtBottom = forceValidation && !isValid;

    return (
        <Stack width="100%" align="center">
            <Animation
                style={{
                    width: '150px',
                    height: '150px',
                }}
                lottieFilesUrl="https://lottiefiles.com/28010-length-measure-tape"
                pathToAnimation="/animations/measure.json"
                loop
            />
            <H0>{getTitle()}</H0>
            <Microcopy style={{ textAlign: 'center', maxWidth: '800px' }}>
                {getDescription()}
            </Microcopy>
            {!isAuthenticated && (
                <>
                    <Spacer height={8} />

                    <Legalese
                        style={{ textAlign: 'center', maxWidth: '800px' }}
                    >
                        ⚠️ FreightSimple does not handle small packages,
                        parcels, vehicles, or home moves.
                    </Legalese>
                </>
            )}
            <Spacer height={64} />
            <div ref={datePickerRef}>
                <MemoizedPickupDateSection
                    pickupCountry={shipment.pickupLocation.address.countryCode}
                    pickupDate={memoPickupDate}
                    setPickupDate={memoSetPickupDate}
                    index={i++}
                />
            </div>

            <LocationSection2
                location={pickupLocation}
                selectedSavedLocationId={selectedPickupSavedLocationId}
                enteringNew={enteringNewPickup}
                savedLocations={savedLocations}
                defaultLocationId={defaultPickupLocationId}
                onSelectSavedLocation={function (
                    savedLocationId,
                    savedLocation
                ) {
                    dispatch(setSelectedPickupSavedLocationId(savedLocationId));
                    dispatch(setPickupLocation(savedLocation));
                }}
                onUpdateLocation={function (location) {
                    dispatch(setPickupLocation(location));
                }}
                onNewLocation={function () {
                    dispatch(setSelectedPickupSavedLocationId(undefined));
                    dispatch(newPickupLocation());
                    dispatch(setEnteringNewPickup(true));
                }}
                onSelectFromAddressBook={onSelectPickupFromAddressBook}
                errorMessages={errorMessagesForPickup}
                warningMessages={warningMessagesForPickup}
                forceValidation={forceValidation}
                index={i++}
                id="pickup-details"
                context={LocationContext.Pickup}
                disablePostalCodeEntry={showingCreateAccountPopup}
                pickupDate={pickupDate}
            />
            <LocationSection2
                location={deliveryLocation}
                selectedSavedLocationId={selectedDeliverySavedLocationId}
                enteringNew={enteringNewDelivery}
                savedLocations={savedLocations}
                defaultLocationId={defaultDeliveryLocationId}
                onSelectSavedLocation={function (
                    savedLocationId,
                    savedLocation
                ) {
                    dispatch(
                        setSelectedDeliverySavedLocationId(savedLocationId)
                    );
                    dispatch(setDeliveryLocation(savedLocation));
                }}
                onUpdateLocation={function (location) {
                    dispatch(setDeliveryLocation(location));
                }}
                onNewLocation={function () {
                    dispatch(setSelectedDeliverySavedLocationId(undefined));
                    dispatch(newDeliveryLocation());
                    dispatch(setEnteringNewDelivery(true));
                }}
                onSelectFromAddressBook={onSelectDeliveryFromAddressBook}
                errorMessages={errorMessagesForDelivery}
                warningMessages={warningMessagesForDelivery}
                forceValidation={forceValidation}
                index={i++}
                id="delivery-details"
                context={LocationContext.Delivery}
                disablePostalCodeEntry={showingCreateAccountPopup}
                pickupDate={pickupDate}
            />
            <ShipmentContentsSection2
                onUpdateLineItem={function (lineItemId, value) {
                    dispatch(updateLineItem({ lineItemId, value }));
                }}
                addAnotherLineItem={function () {
                    dispatch(addLineItem());
                }}
                removeLineItemById={function (lineItemId) {
                    setNmfcErrors((s) => {
                        s.delete(lineItemId);
                        return s;
                    });
                    dispatch(removeLineItem(lineItemId));
                }}
                savedCommodities={savedCommodities}
                onRearrangeLengthWidth={function (lineItemId) {
                    dispatch(rearrangeLengthWidth(lineItemId));
                }}
                lineItems={lineItems}
                forceValidation={forceValidation}
                errorMessageForLineItems={errorMessagesForLineItems}
                overallErrorMessage={overallLineItemsErrorMessage}
                shouldRenderFreightClass={freightClassRequired}
                index={i++}
                setHasNmfcError={function (
                    lineItemId: UUID,
                    hasNmfcError: boolean
                ) {
                    if (hasNmfcError) {
                        setNmfcErrors((s) => {
                            s.add(lineItemId);
                            return s;
                        });
                    } else {
                        setNmfcErrors((s) => {
                            s.delete(lineItemId);
                            return s;
                        });
                    }
                }}
                addLineItemFromSavedCommodity={onAddLineItemFromSavedCommodity}
            />
            <InsuranceSection
                index={i++}
                addInsuranceToShipment={addInsuranceToShipment}
                onAddInsuranceToShipmentChanged={function (newValue) {
                    dispatch(onAddInsuranceToShipmentChanged(newValue));
                }}
                insuranceAmount={insuranceAmount}
                onSetInsuranceAmount={function (newValue) {
                    dispatch(onSetInsuranceAmount(newValue));
                }}
                insuranceCurrency={insuranceCurrency}
                onSetInsuranceCurrency={function (newValue) {
                    dispatch(onSetInsuranceCurrency(newValue));
                }}
                forceValidation={forceValidation}
                errorMessageForInsurance={insuranceErrorMessage}
            />
            {isAuthenticated && (
                <ReferenceNumbersSection
                    index={i++}
                    pickupReferenceNumber={pickupReferenceNumber}
                    deliveryReferenceNumber={deliveryReferenceNumber}
                    onPickupReferenceNumberChanged={function (newValue) {
                        dispatch(setPickupReferenceNumber(newValue));
                    }}
                    onDeliveryReferenceNumberChanged={function (newValue) {
                        dispatch(setDeliveryReferenceNumber(newValue));
                    }}
                />
            )}
            {
                // Don't show the branches view unless we are auth'd
                isAuthenticated &&
                // Only show if the current user is not coded to a branch
                currentUserBranchId === undefined &&
                // Only show if we have branches
                branches.length > 0 && (
                    <BranchesSection
                        index={i++}
                        branchId={branchId}
                        onBranchIdChanged={function (newValue) {
                            dispatch(setBranchId(newValue));
                        }}
                        branches={branches}
                        forceValidation={forceValidation}
                        errorMessage={branchesErrorMessage}
                    />
                )
            }
            <Spacer height={16} />
            {isPickupDateHoliday && (
                <BookShipmentDetailsErrorWarning>
                    <div>
                        The selected date is a holiday in the country of the
                        pickup location.
                        <br />
                        Please{' '}
                        <LinkButton onClick={scrollToPickupDate}>
                            change the date
                        </LinkButton>{' '}
                        to get instant quotes.
                    </div>
                </BookShipmentDetailsErrorWarning>
            )}
            <SlideDown show={quoteRequestError}>
                <BookShipmentDetailsErrorWarning>
                    Something went wrong. Please retry your quote!
                </BookShipmentDetailsErrorWarning>
            </SlideDown>
            <Stack align="left">
                <HorizontalStack>
                    <LinkButton onClick={onReset}>Start Again</LinkButton>
                    <Spacer width={16} />
                    <Button
                        onClick={onGetQuotesOrCreateAccount}
                        size="large"
                        loading={submittingQuotes}
                        icon="arrow-right"
                        disabled={isPickupDateHoliday || isPickupDateWeekend}
                    >
                        Get instant quotes from carriers
                    </Button>
                </HorizontalStack>
            </Stack>
            {!showErrorsAtBottom && warnings.length > 0 && (
                <>
                    <Spacer height={24} />
                    <div style={{ fontSize: '16px', color: Colors.LightText }}>
                        <BookShipmentWarningMessages warnings={warnings} />
                    </div>
                </>
            )}
            {showErrorsAtBottom && (
                <>
                    <Spacer height={8} />
                    <ErrorMessage>Please correct the above errors</ErrorMessage>
                </>
            )}
            <Spacer height={128} />
        </Stack>
    );
}

export default BookShipmentDetailsScreen;
