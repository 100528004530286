import Banner, { BannerStyle } from 'Cargo/Controls/Banner';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import { ShipmentMap } from 'Cargo/Maps/ShipmentMap';
import useConfirmModal from 'Cargo/Modal/useConfirmModal';
import LoadingShipment from 'Features/BookShipment/Components/LoadingShipment';
import ShipmentNotFound from 'Features/BookShipment/Components/ShipmentNotFound';
import { loadFromShipmentToModify } from 'Features/BookShipment/Slices/bookShipmentSlice';
import { useChangePickupDate } from 'Features/ChangePickupDate/Hooks/useChangePickupDate';
import { useCoupon } from 'Features/Coupons/Hooks/useCoupon';
import { useModifyShipment } from 'Features/ModifyShipment/Hooks/useModifyShipment';
import { describeDocumentType } from 'Helpers/describeDocumentType';
import { describeHours } from 'Helpers/describeHours';
import useInterval from 'Hooks/useInterval';
import { useOnce } from 'Hooks/useOnce';
import useQuery from 'Hooks/useQuery';
import { useShipmentService } from 'Services/ShipmentService';
import { useSavedLocationsApi, useShipmentsApi } from 'apis';
import {
    Document,
    DocumentType,
    Shipment,
    ShipmentState,
    TrackingInfo,
} from 'generated-openapi-client';
import { Invoice } from 'generated-openapi-client/models/Invoice';
import { LocationContext } from 'generated-openapi-client/models/LocationContext';
import moment from 'moment';
import { useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import styled from 'styled-components/macro';
import { nextBusinessDay } from '../BookShipment/Helpers/nextBusinessDay';
import AdditionalOptions from './Components/AdditionalOptions';
import BillingSection from './Components/BillingSection';
import { CustomsDocsSection } from './Components/CustomsDocsSection';
import DetailsSection from './Components/DetailsSection';
import DocumentsSection, {
    DocumentsSectionItem,
} from './Components/DocumentsSection';
import JumpBar from './Components/JumpBar';
import NextStepsForDeliverySection from './Components/NextStepsForDeliverySection';
import NextStepsSection from './Components/NextStepsSection';
import TopBar from './Components/TopBar';
import TrackingSection from './Components/TrackingSection';
import useChangeBrokerAfterBookingModal from './Modals/ChangeBrokerAfterBookingModal';
import useModifyAddressAfterBookingModal from './Modals/ModifyAddressAfterBookingModal';
import useModifyContactAfterBookingModal from './Modals/ModifyContactAfterBookingModal';
import useModifyReferenceAndNotesAfterBookingModal from './Modals/ModifyReferenceAndNotesAfterBooking';

function mapForShipment(shipment: Shipment) {
    return (
        <ShipmentMap
            pickup={shipment.pickupLocation}
            delivery={shipment.deliveryLocation}
            shipmentState={shipment.shipmentState}
        />
    );
}

function topBarForShipment(shipment: Shipment) {
    const proNumber = shipment.proNumber;

    const deliveryAppointment = shipment.deliveryLocation.accessorials.includes(
        'SCHEDULING_APPOINTMENT_REQUIRED'
    );

    return (
        <TopBar
            shipmentState={shipment.shipmentState}
            pickupLocation={shipment.pickupLocation}
            deliveryLocation={shipment.deliveryLocation}
            pickupDate={shipment.pickupDate}
            pickupHours={shipment.pickupLocation.hours}
            appointmentDate={shipment.appointmentDate}
            expectedDeliveryDate={shipment.expectedDeliveryDate}
            expectedDeliveryHours={shipment.expectedDeliveryHours}
            actualDeliveryDate={shipment.actualDeliveryDate}
            actualDeliveryTime={shipment.actualDeliveryTime}
            predictedLatestDeliveryDate={shipment.latestedPredictedDeliveryDate}
            proNumber={proNumber || 'Pending'}
            deliveryAppointment={deliveryAppointment}
        />
    );
}

function jumpBarForShipment(shipment: Shipment) {
    console.log(`jumpBarForShipment`, { shipment });
    return (
        <JumpBar
            shipmentState={shipment.shipmentState}
            trackingId={shipment.trId}
        />
    );
}

function customsDocsSectionForShipment(
    shipment: Shipment,
    onUploaded: () => void
) {
    return (
        <CustomsDocsSection
            shipmentId={shipment.shipmentId}
            onUploaded={onUploaded}
        />
    );
}

interface NextStepsSectionForShipmentProps {
    shipment: Shipment;
}

function NextStepsSectionForShipment(props: NextStepsSectionForShipmentProps) {
    const { shipment } = props;
    const { downloadPickupPackage } = useShipmentService();

    if (shipment.shipmentState !== ShipmentState.BookingConfirmed) {
        return <></>;
    }

    function onDownload() {
        downloadPickupPackage(shipment.shipmentId);
    }

    return <NextStepsSection onDownload={onDownload} />;
}

function NextStepsForDeliverySectionForShipment(
    props: NextStepsSectionForShipmentProps
) {
    const { shipment } = props;

    if (shipment.shipmentState !== ShipmentState.InTransit) {
        return <></>;
    }

    const deliveringByAppointment =
        shipment.deliveryLocation.accessorials.includes(
            'SCHEDULING_APPOINTMENT_REQUIRED'
        ) || shipment.appointmentDate !== undefined;

    return (
        <NextStepsForDeliverySection
            deliveringByAppointment={deliveringByAppointment}
            appointmentSet={shipment.appointmentDate !== undefined}
        />
    );
}

interface DetailsSectionForShipmentProps {
    shipment: Shipment;
    trackingInfo: TrackingInfo | undefined;
    onReload: () => Promise<void>;
}

function DetailsSectionForShipment(props: DetailsSectionForShipmentProps) {
    const { shipment } = props;
    const quote = shipment.selectedQuote;
    const onChangePickupDate = useChangePickupDate(shipment);
    const showChangeBrokerModal = useChangeBrokerAfterBookingModal(
        shipment.shipmentId,
        shipment.shipmentState,
        shipment.broker
    );

    const onChangePickupContact = useModifyContactAfterBookingModal(
        shipment,
        LocationContext.Pickup
    );
    const onChangeDeliveryContact = useModifyContactAfterBookingModal(
        shipment,
        LocationContext.Delivery
    );

    const onChangePickupAddress = useModifyAddressAfterBookingModal(
        shipment,
        LocationContext.Pickup
    );
    const onChangeDeliveryAddress = useModifyAddressAfterBookingModal(
        shipment,
        LocationContext.Delivery
    );

    const onChangePickupReferenceAndNotes =
        useModifyReferenceAndNotesAfterBookingModal(
            shipment,
            LocationContext.Pickup
        );
    const onChangeDeliveryReferenceAndNotes =
        useModifyReferenceAndNotesAfterBookingModal(
            shipment,
            LocationContext.Delivery
        );

    if (quote === undefined) {
        return <></>;
    }

    const lineItems = shipment.lineItems;
    const shipmentState = shipment.shipmentState;
    const pickupLocation = shipment.pickupLocation;
    const deliveryLocation = shipment.deliveryLocation;
    const pickupContact = shipment.pickupContact;
    const deliveryContact = shipment.deliveryContact;
    const pickupDate = moment(shipment.pickupDate).startOf('day');
    const actualDeliveryDate = shipment.actualDeliveryDate;
    const actualDeliveryTime = shipment.actualDeliveryTime;
    const proNumber = shipment.proNumber;
    const expectedDeliveryDate = shipment.expectedDeliveryDate;
    const latestExpectedDeliveryDate =
        shipment.latestedPredictedDeliveryDate ||
        quote.latestEstimatedDeliveryDate;
    const pickupReferenceNumber = shipment.pickupReferenceNumber;
    const deliveryReferenceNumber = shipment.deliveryReferenceNumber;
    const pickupBoothNumber = shipment.pickupBoothNumber;
    const deliveryBoothNumber = shipment.deliveryBoothNumber;
    const broker = shipment.broker;
    const addInsuranceToShipment = shipment.addInsuranceToShipment;
    const insuranceAmount = shipment.insuranceAmount;
    const insuranceCurrency = shipment.insuranceCurrency;
    const equipmentType = shipment.equipmentType;
    const exclusiveUse = shipment.exclusiveUseNeeded;
    const tarpRequired = shipment.tarpRequired;
    const linearFeet = shipment.linearFeet;

    const deliveryHours = describeHours(shipment.deliveryLocation.hours);
    const deliveryDeadline = shipment.deliveryDeadline;

    const pickupDeadline = shipment.pickupDeadline;

    const trackingLines = props.trackingInfo?.trackingLines;
    const mostRecentTrackingLine =
        trackingLines !== undefined
            ? trackingLines[trackingLines.length - 1]
            : undefined;

    return (
        <DetailsSection
            pickupDeadline={pickupDeadline}
            lineItems={lineItems}
            shipmentState={shipmentState}
            pickupLocation={pickupLocation}
            deliveryLocation={deliveryLocation}
            pickupContact={pickupContact}
            deliveryContact={deliveryContact}
            pickupDate={pickupDate}
            deliveryHours={deliveryHours}
            deliveryDeadline={deliveryDeadline}
            quote={quote}
            actualDeliveryDate={actualDeliveryDate}
            actualDeliveryTime={actualDeliveryTime}
            proNumber={proNumber}
            expectedDeliveryDate={expectedDeliveryDate}
            latestExpectedDeliveryDate={latestExpectedDeliveryDate}
            pickupReferenceNumber={pickupReferenceNumber}
            deliveryReferenceNumber={deliveryReferenceNumber}
            pickupBoothNumber={pickupBoothNumber}
            deliveryBoothNumber={deliveryBoothNumber}
            broker={broker}
            addInsuranceToShipment={addInsuranceToShipment}
            insuranceAmount={insuranceAmount}
            insuranceCurrency={insuranceCurrency}
            equipmentType={equipmentType}
            exclusiveUse={exclusiveUse}
            tarpRequired={tarpRequired}
            linearFeet={linearFeet}
            mostRecentTrackingLine={mostRecentTrackingLine}
            onChangePickupDate={async function () {
                await onChangePickupDate();
                props.onReload();
            }}
            onChangeBroker={async function () {
                const changed = await showChangeBrokerModal();
                if (changed) {
                    props.onReload();
                }
            }}
            onModifyPickupAddress={async function () {
                const changed = await onChangePickupAddress();
                if (changed) {
                    props.onReload();
                }
            }}
            onModifyDeliveryAddress={async function () {
                const changed = await onChangeDeliveryAddress();
                if (changed) {
                    props.onReload();
                }
            }}
            onModifyPickupContact={async function () {
                const changed = await onChangePickupContact();
                if (changed) {
                    props.onReload();
                }
            }}
            onModifyDeliveryContact={async function () {
                const changed = await onChangeDeliveryContact();
                if (changed) {
                    props.onReload();
                }
            }}
            onModifyPickupReferenceAndNotes={async function () {
                const changed = await onChangePickupReferenceAndNotes();
                if (changed) {
                    props.onReload();
                }
            }}
            onModifyDeliveryReferenceAndNotes={async function () {
                const changed = await onChangeDeliveryReferenceAndNotes();
                if (changed) {
                    props.onReload();
                }
            }}
        />
    );
}

interface FailedPaymentsBannerProps {
    invoices: Invoice[];
}

function FailedPaymentsBanner(props: FailedPaymentsBannerProps) {
    const anyFailedPayments = props.invoices.some(
        (invoice) => invoice.lastPaymentAttemptFailed
    );

    if (!anyFailedPayments) {
        return <></>;
    }

    return (
        <Banner
            bannerStyle={BannerStyle.Error}
            message="Please see the billing section below to resolve the failed payment. Don't worry - your pickup is still booked and the shipment will proceed."
        />
    );
}

interface BillingSectionForShipmentProps {
    shipment: Shipment;
    invoices: Invoice[];
}

function BillingSectionForShipment(props: BillingSectionForShipmentProps) {
    const { shipment, invoices } = props;
    const { downloadInvoice } = useShipmentService();

    return (
        <BillingSection
            invoices={invoices}
            onDownloadInvoice={function (invoiceIdentifier, key) {
                downloadInvoice(shipment.shipmentId, invoiceIdentifier, key);
            }}
        />
    );
}

interface TrackingSectionForShipmentProps {
    shipment: Shipment;
    trackingInfo: TrackingInfo | undefined;
}

function TrackingSectionForShipment(props: TrackingSectionForShipmentProps) {
    const { shipment, trackingInfo } = props;

    if (trackingInfo === undefined || trackingInfo.trackingLines.length === 0) {
        return <></>;
    }

    const lines = trackingInfo.trackingLines.map((line) => {
        return {
            message: line.message,
            time: moment(line.timestamp),
        };
    });

    return (
        <TrackingSection
            shipmentState={shipment.shipmentState}
            lines={lines}
            trackingNote={shipment.trackingNote}
        />
    );
}

interface DocumentsSectionForShipmentProps {
    shipment: Shipment;
    documentItems: Array<DocumentsSectionItem>;
}

function DocumentsSectionForShipment(props: DocumentsSectionForShipmentProps) {
    return <DocumentsSection items={props.documentItems} />;
}

function useConfirmThenWriteIntercomMessage(
    intercomMessage: string,
    confirmationMessage: string
): () => void {
    const { showNewMessages } = useIntercom();
    const confirm = useConfirmModal('Confirm', confirmationMessage);

    async function trigger() {
        const confirmed = await confirm();

        if (confirmed) {
            showNewMessages(intercomMessage);
        }
    }

    return trigger;
}

interface AdditionalOptionsForShipmentProps {
    shipment: Shipment;
    onReloadShipment: () => Promise<void>;
}

function AdditionalOptionsForShipment(
    props: AdditionalOptionsForShipmentProps
) {
    const [modifyShipmentExperienceEnabled, setModifyShipmentExperience] =
        useState(false);
    useHotkeys('ctrl+m', () =>
        setModifyShipmentExperience(!modifyShipmentExperienceEnabled)
    );

    const { shipment, onReloadShipment } = props;
    const { show } = useIntercom();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const savedLocationsApi = useSavedLocationsApi();
    const onModifyShipment = useModifyShipment(shipment);
    const onChangePickupDate = useChangePickupDate(shipment);

    const onCancel = useConfirmThenWriteIntercomMessage(
        'Hello! I would like to cancel this shipment.',
        'Cancelling this shipment will require the assistance of FreightSimple Customer Support. Would you like to continue?'
    );

    const onModifyViaIntercom = useConfirmThenWriteIntercomMessage(
        'Hello! I would like to modify this shipment.',
        'Modifying this shipment will require the assistance of FreightSimple Customer Support. However, this is not always possible. Would you like to continue?'
    );

    function onModify() {
        if (modifyShipmentExperienceEnabled) {
            onModifyShipment();
        } else {
            onModifyViaIntercom();
        }
    }

    function onSubmitClaim() {
        navigate(`/submit-claim?shipmentId=${props.shipment.shipmentId}`);
    }

    async function onRepeatShipment() {
        const response = await savedLocationsApi.getAllSavedLocations();
        const savedLocations = response.items;
        dispatch(
            loadFromShipmentToModify({
                shipment: {
                    ...shipment,
                    pickupDate: nextBusinessDay().format('YYYY-MM-DD'),
                },
                savedLocations,
            })
        );
        navigate('/book/details');
    }

    return (
        <>
            <AdditionalOptions
                onLiveChat={() => show()}
                onChangePickupDate={async function () {
                    await onChangePickupDate();
                    await onReloadShipment();
                }}
                onHelpCenter={() =>
                    window.open('https://help.freightsimple.com')
                }
                onCancelShipment={onCancel}
                onModifyShipment={onModify}
                onRepeatShipment={onRepeatShipment}
                onSubmitClaim={onSubmitClaim}
                shipment={shipment}
            />
        </>
    );
}

interface AfterBookingProps {
    shipment: Shipment;
    onReloadShipment: () => Promise<void>;
    documentItems: Array<DocumentsSectionItem>;
}

const Container = styled.div`
    border: 1px solid #ccc;
    width: 1216px;
    position: relative;
    z-index: 100;
    background-color: white;
    top: -72px;
    border-radius: 3px;
`;

function AfterBooking(props: AfterBookingProps) {
    const shipment = props.shipment;
    const shipmentState = shipment.shipmentState;
    const [trackingInfo, setTrackingInfo] = useState<
        TrackingInfo | undefined
    >();
    const shipmentsApi = useShipmentsApi();
    const { shipmentId } = shipment;
    const { getInvoices } = useShipmentService();
    const [invoices, setInvoices] = useState<Invoice[]>([]);

    useOnce(async function () {
        const _invoices = await getInvoices(shipment.shipmentId);
        setInvoices(_invoices);
    });

    useOnce(async () => {
        loadTracking();
    });

    async function loadTracking() {
        const trackingInfo = await shipmentsApi.getShipmentsGetTracking({
            shipmentId,
        });

        setTrackingInfo(trackingInfo);
    }

    function showCustomsDocsSection() {
        return (
            shipment.needsCustomsDocs &&
            (shipment.shipmentState === ShipmentState.BookingConfirmed ||
                shipment.shipmentState === ShipmentState.OnHold ||
                shipment.shipmentState === ShipmentState.InTransit)
        );
    }

    return (
        <>
            {mapForShipment(shipment)}
            <Stack width="100%" align="center">
                <Container>
                    <Stack
                        width="1216px"
                        align="left"
                        style={{ padding: '64px', paddingTop: '32px' }}
                    >
                        {topBarForShipment(shipment)}
                        <Spacer height={32} />
                        <FailedPaymentsBanner invoices={invoices} />
                        <Spacer height={32} />
                        {jumpBarForShipment(shipment)}
                        <Spacer height={16} />
                        {showCustomsDocsSection() &&
                            customsDocsSectionForShipment(
                                shipment,
                                props.onReloadShipment
                            )}
                        {!showCustomsDocsSection() && (
                            <>
                                <NextStepsSectionForShipment
                                    shipment={shipment}
                                />
                                <NextStepsForDeliverySectionForShipment
                                    shipment={shipment}
                                />
                            </>
                        )}
                        <DetailsSectionForShipment
                            shipment={shipment}
                            trackingInfo={trackingInfo}
                            onReload={props.onReloadShipment}
                        />
                        {(shipmentState === ShipmentState.BookingConfirmed ||
                            shipmentState === ShipmentState.InTransit) && (
                            <TrackingSectionForShipment
                                shipment={shipment}
                                trackingInfo={trackingInfo}
                            />
                        )}
                        <DocumentsSectionForShipment
                            shipment={shipment}
                            documentItems={props.documentItems}
                        />
                        <BillingSectionForShipment
                            shipment={shipment}
                            invoices={invoices}
                        />
                        {shipmentState === ShipmentState.Delivered && (
                            <TrackingSectionForShipment
                                shipment={shipment}
                                trackingInfo={trackingInfo}
                            />
                        )}
                        <AdditionalOptionsForShipment
                            shipment={shipment}
                            onReloadShipment={props.onReloadShipment}
                        />
                    </Stack>
                </Container>
            </Stack>
        </>
    );
}

function ViewShipmentScreen() {
    const query = useQuery();

    const shipmentsService = useShipmentService();

    const shipmentId = query.shipmentId as string;

    const [shipment, setShipment] = useState<Shipment | undefined>();
    const [shipmentNotFound, setShipmentNotFound] = useState(false);
    const [documentItems, setDocumentItems] = useState<
        Array<DocumentsSectionItem>
    >([]);
    const { getDocuments, downloadDocument } = useShipmentService();

    const { refreshCoupon } = useCoupon();

    // Need to make sure we clear the coupon ui after booking is complete
    // since booking is highly async - this is the easiest way
    useOnce(function () {
        refreshCoupon();
    });

    function addIfNotExist(
        documents: Array<DocumentsSectionItem>,
        documentType: DocumentType
    ) {
        const document = documents.find((d) => d.documentType === documentType);

        if (document === undefined) {
            documents.push({
                existsYet: false,
                creationDate: undefined,
                documentDescription: describeDocumentType(documentType),
                onDownloadDocument: () => {},
                documentType: documentType,
            });
        }
    }

    function convert(document: Document): DocumentsSectionItem {
        return {
            onDownloadDocument: () => {
                downloadDocument(document.documentId, document.documentType);
            },
            documentDescription: describeDocumentType(document.documentType),
            creationDate: moment(document.createdAt),
            existsYet: true,
            documentType: document.documentType,
        };
    }

    async function loadShipment() {
        try {
            const response = await shipmentsService.getShipment(shipmentId);
            console.log(`!!!! response`, { response });

            setShipment(response);

            const documents = (await getDocuments(response.shipmentId)).map(
                (d) => convert(d)
            );

            addIfNotExist(documents, DocumentType.ProofOfDelivery);

            setDocumentItems(documents);
        } catch (e) {
            if (e instanceof Response && e.status === 404) {
                setShipmentNotFound(true);
            }
            console.warn({ e });
        }
    }

    // Refresh the page every two minutes
    useInterval(function () {
        loadShipment();
    }, 2 * 60 * 1000);

    useOnce(async () => {
        loadShipment();
    });

    if (shipmentNotFound) {
        return <ShipmentNotFound />;
    }
    if (!shipment) {
        return <LoadingShipment />;
    }

    return (
        <AfterBooking
            shipment={shipment}
            onReloadShipment={loadShipment}
            documentItems={documentItems}
        />
    );
}
export default ViewShipmentScreen;
