import axios from 'axios';
import { OpenFreightClaimRequest } from 'generated-openapi-client';

export class FreightClaimsApi {
    baseUrl: string;
    accessToken: string;

    constructor(baseUrl: string, accessToken: string) {
        this.baseUrl = baseUrl;
        this.accessToken = accessToken;
    }

    async upload(
        request: OpenFreightClaimRequest,
        originalInvoiceFiles: Array<File>,
        photosFiles: Array<File>,
        otherDocumentsFiles: Array<File>
    ): Promise<void> {
        const options = {
            headers: {
                Authorization: `Bearer ${this.accessToken}`,
                'Content-Type': 'multipart/form-data',
            },
        };

        const formData = new FormData();

        originalInvoiceFiles.forEach(function (file, index) {
            console.log(`Appending ${index} ${file.name} ${file.size}`);
            formData.append(`originalInvoiceFile${index}`, file, file.name);
        });

        photosFiles.forEach(function (file, index) {
            console.log(`Appending ${index} ${file.name} ${file.size}`);
            formData.append(`photosFile${index}`, file, file.name);
        });

        otherDocumentsFiles.forEach(function (file, index) {
            console.log(`Appending ${index} ${file.name} ${file.size}`);
            formData.append(`otherDocumentsFile${index}`, file, file.name);
        });

        const encodedRequest = encodeURIComponent(JSON.stringify(request));

        await axios.post(
            `${this.baseUrl}/freight-claims/open?request=${encodedRequest}`,
            formData,
            options
        );
    }
}
