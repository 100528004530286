/* eslint-disable local-rules/no-relative-imports */
import { CustomsDocsApi } from 'Apis/CustomsDocsApi';
import { FreightClaimsApi } from 'Apis/FreightClaimsApi';
import { PhotosApi } from 'Apis/PhotosApi';
import { UsersApi2 } from 'Apis/UsersApi2';
import { useAuthentication } from 'Features/Authentication/Slices/authenticationSlice';
import { apiServerUrl } from './environment';
import {
    Configuration,
    GetStartedApi,
    InvitationsApi,
    InvoicesApi,
    PaymentMethodsApi,
    PostalCodesApi,
    SavedBrokersApi,
    SavedCommoditiesApi,
    SavedLocationsApi,
    ShipmentsApi,
    TrackingApi,
    UsersApi,
} from './generated-openapi-client';

function getFullStorySessionUrl() {
    try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        return FS !== undefined ? FS.getCurrentSessionURL(true) : undefined;
    } catch (e) {
        return '';
    }
}

export function getConfig() {
    return {
        basePath: apiServerUrl(),
        headers: {
            fullStorySessionUrl: getFullStorySessionUrl(),
        },
    };
}

export function useShipmentsApi(): ShipmentsApi {
    const { accessToken } = useAuthentication();

    return new ShipmentsApi(
        new Configuration({ ...getConfig(), accessToken: accessToken })
    );
}

export function useTrackingApi(): TrackingApi {
    return new TrackingApi(new Configuration({ ...getConfig() }));
}

export function usePostalCodesApi(): PostalCodesApi {
    return new PostalCodesApi(new Configuration({ ...getConfig() }));
}

export function useUsersApi(): UsersApi {
    const { accessToken } = useAuthentication();

    return new UsersApi(
        new Configuration({ ...getConfig(), accessToken: accessToken })
    );
}

export function useUsersApi2(): UsersApi2 {
    const { accessToken } = useAuthentication();

    return new UsersApi2(getConfig().basePath, accessToken);
}

export function useCustomsDocsApi(): CustomsDocsApi {
    const { accessToken } = useAuthentication();

    return new CustomsDocsApi(getConfig().basePath, accessToken);
}

export function usePhotosApi(): PhotosApi {
    const { accessToken } = useAuthentication();

    return new PhotosApi(getConfig().basePath, accessToken);
}

export function useFreightClaimsApi(): FreightClaimsApi {
    const { accessToken } = useAuthentication();

    return new FreightClaimsApi(getConfig().basePath, accessToken);
}

export function useInvoicesApi(): InvoicesApi {
    const { accessToken } = useAuthentication();

    return new InvoicesApi(
        new Configuration({ ...getConfig(), accessToken: accessToken })
    );
}

export function useSavedLocationsApi(): SavedLocationsApi {
    const { accessToken } = useAuthentication();

    return new SavedLocationsApi(
        new Configuration({ ...getConfig(), accessToken: accessToken })
    );
}

export function useSavedBrokersApi(): SavedBrokersApi {
    const { accessToken } = useAuthentication();

    return new SavedBrokersApi(
        new Configuration({ ...getConfig(), accessToken: accessToken })
    );
}

export function useSavedCommoditiesApi(): SavedCommoditiesApi {
    const { accessToken } = useAuthentication();

    return new SavedCommoditiesApi(
        new Configuration({ ...getConfig(), accessToken: accessToken })
    );
}

export function usePaymentMethodsApi(): PaymentMethodsApi {
    const { accessToken } = useAuthentication();

    return new PaymentMethodsApi(
        new Configuration({ ...getConfig(), accessToken: accessToken })
    );
}

export function createUsersApi(accessToken: string): UsersApi {
    return new UsersApi(
        new Configuration({ ...getConfig(), accessToken: accessToken })
    );
}

export function useGetStartedApi(): GetStartedApi {
    const { accessToken } = useAuthentication();

    return new GetStartedApi(
        new Configuration({ ...getConfig(), accessToken: accessToken })
    );
}

export function useInvitationsApi(): InvitationsApi{
    return new InvitationsApi(new Configuration({ ...getConfig() }));
}

export type Accessorials = string[];
