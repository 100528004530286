import Stack from 'Cargo/Layout/Stack';
import { ErrorMessage } from 'Cargo/Text/Text';
import {
    ClaimLineItem,
    Currency,
    FreightClaimType,
    Shipment,
} from 'generated-openapi-client';
import { DamageClaimLineItemRow } from './DamageClaimLineItemRow';
import { SubmitClaimSection } from './SubmitClaimSection';

interface DamageClaimLineItemsSectionProps {
    shipment: Shipment;
    currency: Currency;
    claimLineItems: ClaimLineItem[];
    setClaimLineItems: (_: ClaimLineItem[]) => void;
    forceValidation: boolean;
}

export function DamageClaimLineItemsSection(
    props: DamageClaimLineItemsSectionProps
) {
    const { currency, claimLineItems } = props;
    const errorMessageForAllRows = props.claimLineItems.every(
        (o) => o.claimType === FreightClaimType.NoDamageOrLoss
    )
        ? 'No damage or loss has been indicated'
        : undefined;
    return (
        <SubmitClaimSection
            index="2"
            title="Lost or Damaged Line Items"
            description="Please tell us which line items of your shipment suffered damage or loss"
            content={
                <Stack align="left">
                    {props.shipment.lineItems.map(function (lineItem, index) {
                        console.log(`!!! DamageClaimLineItemsSection`, {
                            props,
                        });
                        return (
                            <DamageClaimLineItemRow
                                lineItem={lineItem}
                                index={index}
                                currency={currency}
                                claimLineItem={claimLineItems[index]}
                                setClaimLineItem={function (
                                    updatedClaimLineItem
                                ) {
                                    claimLineItems[index] =
                                        updatedClaimLineItem;
                                    console.log(`!!! setClaimLineItem`, {
                                        claimLineItems,
                                    });
                                    // do the ... to force a new array and cause react to rerender
                                    props.setClaimLineItems([
                                        ...claimLineItems,
                                    ]);
                                }}
                                forceValidation={props.forceValidation}
                            />
                        );
                    })}
                    {props.forceValidation && errorMessageForAllRows && (
                        <ErrorMessage>{errorMessageForAllRows}</ErrorMessage>
                    )}
                </Stack>
            }
        />
    );
}
