import { Accessorials } from 'apis';
import Box from 'Cargo/Layout/Box';
import Spacer from 'Cargo/Layout/Spacer';
import LocationDetails from 'Features/Locations/Components/LocationDetails';
import { PostalCodeChangeEvent } from 'Features/Locations/Components/PostalCodeInput';
import { AddLocationLocationType } from 'Features/Locations/Types/locationTypes';
import { LocationErrors } from 'Features/Locations/Validators/errorMessagesForLocation';
import { LocationContext, LocationType } from 'generated-openapi-client';
import { useState } from 'react';

interface NewLocationDetailsProps {
    locationErrorMessages: LocationErrors;
    locationWarningMessages: LocationErrors;
    context: LocationContext;
    setLocation: (_: AddLocationLocationType) => void;
    location: AddLocationLocationType;
    forceValidation: boolean;
    disablePostalCodeEntry: boolean;
    pickupDate: string;
}

function NewLocationDetails(props: NewLocationDetailsProps) {
    const { setLocation, location } = props;
    const address = location.address;
    const [showMap, setShowMap] = useState(false);
    const [distributionWarehouseName, setDistributionWarehouseName] = useState<
        string | undefined
    >(undefined);
    const accessorials = location.accessorials;

    function removeItemsStartingWith(values: string[], prefix: string) {
        return values.filter((v) => !v.startsWith(prefix));
    }

    function onPostalCodeChange(
        e: PostalCodeChangeEvent,
        _distributionWarehouseName: string | undefined
    ) {
        if (e.latitudeLongitude !== undefined) {
            setShowMap(true);
        } else {
            setShowMap(false);
        }

        const newLocation = {
            ...location,
            ...{ address: e.address, latitudeLongitude: e.latitudeLongitude },
        };

        setLocation(newLocation);
        setDistributionWarehouseName(_distributionWarehouseName);
    }

    function setSchedulingAccessorials(schedulingAccessorials: Accessorials) {
        const updatedAccessorials = removeItemsStartingWith(
            accessorials,
            'SCHEDULING_'
        );
        updatedAccessorials.push(...schedulingAccessorials);

        setLocation({
            ...location,
            ...{ accessorials: updatedAccessorials },
        });
    }

    function setLocationType(locationType: LocationType) {
        const newLocation = {
            ...location,
            ...{ locationType },
        };

        setLocation(newLocation);
    }

    function setLogisticsAccessorials(logisticsAccessorials: Accessorials) {
        const updatedAccessorials = removeItemsStartingWith(
            accessorials,
            'LOGISTICS_'
        );
        updatedAccessorials.push(...logisticsAccessorials);
        setLocation({
            ...location,
            ...{ accessorials: updatedAccessorials },
        });
    }

    return (
        <>
            <Spacer height={24} />
            <Box style={{ paddingTop: '32px', paddingBottom: '32px' }}>
                <LocationDetails
                    locationErrorMessages={props.locationErrorMessages}
                    locationWarningMessages={props.locationWarningMessages}
                    forceValidation={props.forceValidation}
                    address={address}
                    location={location}
                    onPostalCodeChange={onPostalCodeChange}
                    showMap={showMap}
                    locationContext={props.context}
                    latitudeLongitude={location.latitudeLongitude}
                    setLocationType={setLocationType}
                    accessorials={accessorials}
                    setSchedulingAccessorials={setSchedulingAccessorials}
                    setLogisticsAccessorials={setLogisticsAccessorials}
                    disablePostalCodeEntry={props.disablePostalCodeEntry}
                    distributionWarehouseName={distributionWarehouseName}
                />
            </Box>
            {/* This is specifically to get the various blocks to be the same height wise, to make transitions cleaner */}
            <Spacer height={21} />
        </>
    );
}
export default NewLocationDetails;
