import { useCustomsDocsApi } from 'apis';
import Colors from 'Cargo/Colors';
import Button from 'Cargo/Controls/Button';
import Icon from 'Cargo/Icons/Icon';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import { useModal } from 'Cargo/Modal/useModal';
import { H3, Legalese, Microcopy } from 'Cargo/Text/Text';
import { UUID } from 'Cargo/Types/types';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components/macro';

interface UploadCustomsDocsModalProps {
    trackingId: UUID;
    onFinished: () => void;
    onCancel: () => void;
}

interface DropContainerProps {
    isDragAccept: boolean;
    isDragReject: boolean;
    isDragActive: boolean;
}

function getColor(props: DropContainerProps) {
    if (props.isDragAccept) {
        return '#00e676';
    }
    if (props.isDragReject) {
        return '#ff1744';
    }
    if (props.isDragActive) {
        return '#2196f3';
    }
    return '#ccc';
}

const DropContainer = styled.div<DropContainerProps>`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 5px;
    border-radius: 20px;
    border-color: ${(props) => getColor(props)};
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;
    width: 480px;
    margin-bottom: 32px;
`;
const SectionTitle = styled.div`
    font-weight: var(--nhu-font-weight-bold);
    font-size: 30px;
    color: var(--freightsimple-color-normal-text);
`;

const SectionSubtitle = styled.div`
    font-weight: var(--nhu-font-weight-light);
    font-size: 18px;
    color: var(--freightsimple-color-light-text);
    text-align: center;

    & strong {
        color: var(--freightsimple-color-normal-text);
    }
`;

function UploadCustomsDocsModal(props: UploadCustomsDocsModalProps) {
    const customsDocsApi = useCustomsDocsApi();
    const [loading, setLoading] = useState(false);
    const [loadingSuccess, setLoadingSuccess] = useState(false);
    const [loadingFailure, setLoadingFailure] = useState(false);

    const [files, setFiles] = useState<Array<File>>([]);
    const onDrop = function (acceptedFiles: Array<File>) {
        setFiles([...files, ...acceptedFiles]);
    };
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        onDrop,
        maxFiles: 10,
        accept: 'application/pdf',
    });

    async function onUpload() {
        setLoadingSuccess(false);
        setLoadingFailure(false);
        setLoading(true);
        try {
            await customsDocsApi.uploadWithTrackingId(props.trackingId, files);
            setLoadingSuccess(true);
            setTimeout(function () {
                props.onFinished();
            }, 1000);
        } catch (e) {
            console.error(`Failure uploading customs docs`, { e });
            setLoadingFailure(true);
        } finally {
            setLoading(false);
        }
    }

    function acceptedFilesCount() {
        if (files.length === 0) {
            return '';
        }

        if (files.length === 1) {
            return ' (1 file)';
        }

        if (files.length > 1) {
            return ` (${files.length} file)`;
        }
    }

    return (
        <Stack align="center" width="100%" style={{ padding: '32px 64px' }}>
            <Icon
                name="globe-americas"
                color={Colors.Blue}
                size={80}
                solid
                style={{ marginBottom: '16px', marginTop: '16px' }}
            />
            <SectionTitle>
                <HorizontalStack>
                    <span>Upload Customs Documents</span>
                </HorizontalStack>
            </SectionTitle>
            <SectionSubtitle>
                <p>
                    If you have the customs documents, please upload them here.
                    This will help ensure this shipment goes smoothly.
                </p>
                <p>A copy must also be provided to the driver at pickup.</p>
            </SectionSubtitle>
            <Stack width="100%" align="center">
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <DropContainer
                    {...getRootProps({
                        isDragActive,
                        isDragAccept,
                        isDragReject,
                    })}
                >
                    <input {...getInputProps()} />

                    <Stack>
                        <Spacer height={32} />
                        <H3>Customs Documents PDF files</H3>
                        <Microcopy>
                            Drag and drop or click to choose a file to upload
                        </Microcopy>
                        <Spacer height={16} />
                        {files.length === 0 && (
                            <Icon
                                name="file-pdf"
                                color="#ccc"
                                size={64}
                                solid
                            />
                        )}

                        <Stack align="left">
                            {files.map(function (af) {
                                return (
                                    <HorizontalStack key={af.name}>
                                        <Icon
                                            name="file-pdf"
                                            color={Colors.Blue}
                                            size={16}
                                            solid
                                            style={{
                                                marginRight: '4px',
                                            }}
                                        />

                                        <Legalese>{af.name}</Legalese>
                                    </HorizontalStack>
                                );
                            })}
                        </Stack>
                        <Spacer height={16} />
                        <Legalese>
                            Multiple files allowed
                        </Legalese>
                        <Spacer height={16} />
                    </Stack>
                </DropContainer>
                <Button
                    size="large"
                    onClick={onUpload}
                    loadingSuccess={loadingSuccess}
                    loading={loading}
                    loadingFailure={loadingFailure}
                    disabled={files.length === 0 || loading || loadingSuccess}
                >
                    Upload Customs Docs {acceptedFilesCount()}
                </Button>
            </Stack>
        </Stack>
    );
}

export function useUploadCustomsDocsModal(trackingId: UUID) {
    const showModal = useModal<boolean>();

    async function show(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            showModal(
                (done) => {
                    return (
                        <UploadCustomsDocsModal
                            onCancel={() => done(false)}
                            onFinished={() => {
                                done(true);
                            }}
                            trackingId={trackingId}
                        />
                    );
                },
                async (data) => {
                    if (data !== undefined) {
                        resolve(data);
                    }
                },
                {
                    preventBackdropClick: false,
                }
            );
        });
    }
    return show;
}
