import LinkButton from 'Cargo/Controls/LinkButton';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import { DocumentType } from 'generated-openapi-client';
import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';
import ViewShipmentSection from './ViewShipmentSection';
import { ViewShipmentSectionTable } from './ViewShipmentSectionTable';

export interface DocumentsSectionItem {
    documentDescription: string;
    existsYet: boolean;
    creationDate: moment.Moment | undefined;
    onDownloadDocument: () => void;
    documentType: DocumentType;
}

const DocumentDescription = styled.div`
    font-weight: var(--nhu-font-weight-medium);
    font-size: 18px;
    color: #545454;
`;
const CreationDate = styled.div`
    font-weight: var(--nhu-font-weight-light);
    font-size: 16px;
    color: #858585;
`;

interface DocumentsSectionProps {
    children?: ReactNode;
    items: Array<DocumentsSectionItem>;
}
function DocumentsSection(props: DocumentsSectionProps) {
    const { items } = props;

    return (
        <ViewShipmentSection
            title="Documents"
            subtitle="Here are all the documents associated with your shipment. "
            id="documents-section"
        >
            <ViewShipmentSectionTable>
                <table>
                    <tbody>
                        {items.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        <HorizontalStack>
                                            <img src="/pdf.png" />
                                            <Spacer width={16} />
                                            <DocumentDescription>
                                                {item.documentDescription}
                                            </DocumentDescription>
                                        </HorizontalStack>
                                    </td>
                                    <td>
                                        <CreationDate>
                                            {item.existsYet
                                                ? item.creationDate?.format(
                                                      'Do MMMM YYYY'
                                                  )
                                                : 'Not yet available'}
                                        </CreationDate>
                                    </td>

                                    <td>
                                        {item.existsYet && (
                                            <LinkButton
                                                regularWeight={true}
                                                onClick={
                                                    item.onDownloadDocument
                                                }
                                                style={{
                                                    fontSize: '16px',
                                                }}
                                            >
                                                Download Document
                                            </LinkButton>
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </ViewShipmentSectionTable>
        </ViewShipmentSection>
    );
}
export default DocumentsSection;
