import Input from 'Cargo/Controls/Input';
import { ErrorMessageType } from 'Cargo/Validation';

interface CurrencyInputProps {
    id?: string;
    label?: string;
    autoComplete?: string;
    value?: number;
    onChange: (e: number | undefined) => void;
    name?: string;
    enabled?: boolean;
    autoFocus?: boolean;
    required?: boolean;
    pattern?: string;
    readOnly?: boolean;
    hidden?: boolean;
    width?: number;
    errorMessage: ErrorMessageType;
    forceValidation: boolean;
    hideErrorIcon?: boolean;
    labelPadding?: number;
    validateOnTimeoutMs?: number;
    placeholder?: string;
}

function formatCurrency(value: string) {
    return parseFloat(parseFloat(value).toFixed(2));
}

export function ClaimCurrencyInput(props: CurrencyInputProps) {
    return (
        <Input
            id={props.id}
            label={'Amount'}
            autoComplete={props.autoComplete}
            value={props.value?.toString()}
            onChange={(value) => {
                props.onChange(
                    value === '' ? undefined : formatCurrency(value)
                );
            }}
            name={props.name}
            enabled={props.enabled}
            autoFocus={props.autoFocus}
            required={props.required}
            readOnly={props.readOnly}
            hidden={props.hidden}
            width={props.width}
            type="number"
            errorMessage={props.errorMessage}
            forceValidation={props.forceValidation}
            hideErrorIcon={props.hideErrorIcon}
            labelPadding={props.labelPadding}
            validateOnTimeoutMs={props.validateOnTimeoutMs}
        />
    );
}
